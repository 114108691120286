import React, { useEffect } from "react";
import { connect } from "react-redux";
import TagCloud from "TagCloud";
import "./TagsCloud.css";
import { TagCloud as TagCloudMobile } from "react-tagcloud";
import { MobileTagCloudWords, TagCloudWords } from "../../data/TagCloud";

const TagsCloud = (props) => {
  useEffect(() => {
    const container = ".tagcloud";
    let radii;

    function radiusValue() {
      if (window.screen.width <= 778) {
        radii = 150;
      } else if (window.screen.width > 778 && window.innerHeight < 700) {
        radii = 250;
      } else if (window.screen.width > 778 && window.innerHeight >= 700) {
        radii = 290;
      }
      return radii;
    }

    const options = {
      radius: radiusValue(),
      maxSpeed: "slow",
      initSpeed: "slow",
      keep: true,
    };

    TagCloud(container, TagCloudWords, options);
  }, []);

  return (
    <div className="TagsCloudContainer">
      <div className="tagcloud"></div>
      <div className="tagcloudMobile">
        <TagCloudMobile minSize={12} maxSize={35} tags={MobileTagCloudWords} />
      </div>
    </div>
  );
};

const ConnectedTagsCloud = connect((state) => {
  return {
    User: state.User,
  };
})(TagsCloud);

export default ConnectedTagsCloud;
