export function Idade(dataNascimento) {
    // Divide a data em dia, mês e ano
    var partesData = dataNascimento.split("/");
    var dia = parseInt(partesData[0]);
    var mes = parseInt(partesData[1]);
    var ano = parseInt(partesData[2]);
  
    // Obtém a data atual
    var dataAtual = new Date();
    var anoAtual = dataAtual.getFullYear();
    var mesAtual = dataAtual.getMonth() + 1; // Adiciona 1 pois os meses são indexados de 0 a 11
    var diaAtual = dataAtual.getDate();
  
    // Calcula a idade
    var idade = anoAtual - ano;
  
    // Ajusta a idade se o aniversário ainda não ocorreu este ano
    if (mesAtual < mes || (mesAtual === mes && diaAtual < dia)) {
      idade--;
    }
   
    return idade;
  }
  

  
  export function obterDataAtual() {
    var dataAtual = new Date();
    var dia = ("0" + dataAtual.getDate()).slice(-2); // Adiciona um zero à esquerda, se necessário
    var mes = ("0" + (dataAtual.getMonth() + 1)).slice(-2); // Adiciona um zero à esquerda, se necessário
    var ano = dataAtual.getFullYear().toString().slice(-2); // Pega apenas os últimos dois dígitos do ano
    return dia + "/" + mes + "/" + ano;
  }