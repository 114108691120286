// ############### ABOUT ############### //

export const AboutText = {
  En: {
    Desc1: "Hello! My name is Bruno, I currently work with Systems Analysis and managment of software development projects. I'm passionate about web development. My interest in web development started in 2017, when some colleagues and I decided to develop a messaging system for the school where we studied. ",
    Desc2: "Fast forward to today, I had the privilege of working in an Industrial Automation company that gave me experience with Development, Maintenance and Improvements of information Systems, as well as experience in systems analysis and implementation projects.",
    Desc3: "In addition, I have a lot of experience developing web applications, both front-end and back-end using base languages such as HTML, CSS, Javascript, Typescript as well as libraries and frameworks such as ReactJs and NodeJs",
    Title: "A Little Bit About Me",
  },
  PTBR: {
    Desc1: "Olá! Meu nome é Bruno, trabalho atualmente com Analise de Sistemas e Gestão de Projetos de desenovlvimento de software . Sou apaixonado em desenvolvimento web . Meu interesse em desenvolvimento web começou em 2017, quando eu e alguns colegas decidimos desenvolver um sistema de mensagens para a escola onde estudavamos. ",
    Desc2: "Avançando para hoje, tive o privilégio de trabalhar em uma empresa de Automação Industrial que me proporcionou experiência com Desenvolvimeto, Manutenção e Melhorias de Sistemas de informação, assim como experiência em projetos de análise e implementação de sistemas.",
    Desc3: "Além disso, tenho bastate experiência desenvolvendo aplicações web, tanto front-end quanto back-end utilizando tanto linguagens de base como HTML, CSS, Javascipt e Typescript bem como bibliotecas e frameworks como ReactJs e NodeJs",
    Title: "Um pouco sobre mim",
  },
};

export const AboutWords = {
  En: ["Adaptability", "Creativity", "Flexibility", "Self-Motivation", "Time Management", "Communication", "Teamwork", "Problem-solving", "Project Management", "Technical Expertise", "Data Analysis", "Presentation Skills", "Networking", "Persistence", "Proactivity"],
  PTBR: ["Adaptabilidade", "Criatividade", "Flexibilidade", "Auto-motivação", "Gerenciamento de tempo", "Comunicação", "Trabalho em equipe", "Solução de problemas", "Gestão de Projetos", "Especialização Técnica", "Análise de dados", "Habilidades de apresentação", "Networking", "Persistência", "Proatividade"],
};
