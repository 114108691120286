import "./Certifications.css";
import { TbCertificate } from "react-icons/tb";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Evento } from "../firebase/metodos";
import { CertificationsData, CertificationSectionData } from "../../data/Certifications";
import { EventsType } from "../../data/Events";

const Certifications = (props) => {
  return (
    <div className="CertificationsContainer">
      <div className="CertificationsTitle">{CertificationSectionData[props.User.Language].Title}</div>
      <div className="Divider">
        {" "}
        <div></div>{" "}
      </div>

      <div className="CertificationsListContainer">
        {CertificationsData[props.User.Language].map((Certificate) => {
          return (
            <div onClick={(e) => Evento(EventsType.Certifications, Certificate)} key={uuidv4()} className="CertificationItem">
              <div className="CertificationIcon">
                <TbCertificate />
              </div>
              <div className="CertificationName">{Certificate}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ConnectedCertifications = connect((state) => {
  return {
    User: state.User,
  };
})(Certifications);

export default ConnectedCertifications;
