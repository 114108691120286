// ############### PROJECTS ############### //

export const ProjectsSectionData = {
  En: {
    Title: "Projects",
    ProjectsDesc: " Here you will find some of the personal projects that I created with each project containing its own case study",
    AcessButton: "Visit",
  },
  PTBR: {
    Title: "Projetos",
    ProjectsDesc: "Aqui você encontrará alguns dos projetos pessoais que criei com cada projeto contendo seu próprio estudo de caso",
    AcessButton: "Acessar",
  },
};

export const ProjectsData = {
  En: [
    {
      ID: "Kanban",
      Name: "Kanban",
      Desc: "I developed a platform for creating and managing Kanban boards, utilizing modern technologies like ReactJs, Vite, TailwindCss, Firebase, Redux, and Typescript for a smooth user experience. Users can customize columns, add tags to cards for organization, and filter information. Drag-and-drop functionality allows easy movement of cards between columns. Tasks can be created within cards, and users have full control over customization. Sharing boards, support for light and dark themes, and integration with Google login are included. The platform offers comprehensive project and task management with options to export to CSV and Excel.",
      Tags: ["ReactJs", "TailwindCss", "Typescript", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Kanban.png",
      ProjectURL: "https://kanban.bkappi.com",
    },
    {
      ID: "Pomodoro",
      Name: "Pomodoro",
      Desc: "I have developed a Pomodoro Timer, inspired by the Pomodoro technique, to aid in time management and enhance productivity. The website allows users to customize settings such as the duration of work and break periods, and offers the option to receive notifications. Additionally, users can create an account to store their settings and usage history in the cloud, facilitating consistent access across any device, and track their progress over time.",
      Tags: ["ReactJs", "TailwindCss", "Typescript", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/PomodoroThumb.png",
      ProjectURL: "https://pomodoro.bkappi.com",
    },

    {
      ID: "Bkappi",
      Name: "Bkappi",
      Desc: "I developed a personal blog to publish articles related to Software Development, Information Security, Project Management, Industrial Automation, and any other subject that may be useful for any developer. The name 'Bkappi' is a wordplay, with the 'B' from Bruno and 'Kappi' from my own surname, but the wordplay lies in the sound of the final result, resembling the word 'Backup', which makes more sense in the way it's pronounced in Portuguese, being very similar to Bkappi.",
      Tags: ["WordPress", "HTML", "CSS", "PHP", "MySQl"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Blog.png",
      ProjectURL: "https://bkappi.com",
    },
    {
      ID: "AssetSense",
      Name: "AssetSense",
      Desc: "I have developed a project called AssetSense, which is a customizable Asset Management software for businesses. It was built using React, JavaScript, CSS, HTML, Firebase, and Redux. The software allows for asset registration and organization based on storage location, type, and other criteria. It offers extensive customization options, including the ability to add new asset types, locations, custom fields, permissions, and more. Users can also record asset withdrawals and returns, while the system generates informative dashboards showcasing data on assets, users, and asset utilization. AssetSense is a comprehensive and adaptable solution for efficient asset management in enterprises.",
      Tags: ["ReactJs", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/AssetSense.png",
      ProjectURL: "https://assetsenseplatform.bkappi.com",
    },
    {
      ID: "ServiceIT",
      Name: "ServiceIT",
      Desc: "In 2022, I developed ServiceIT, a centralized platform for managing customer service calls, customizable with categories, subcategories, types of urgency and other options, both for the Support Team and for the customer. I did as my final project, surprising myself with the result and developing beyond expectations. I applied knowledge in HTML, CSS, Javascript, ReactJS, NodeJS, Redux and Firebase, as well as design and planning. Application has great potential despite some incomplete features.",
      Tags: ["ReactJs", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/ServiceIT.png",
      ProjectURL: "https://serviceitplatform.bkappi.com",
    },
    {
      ID: "Flashcards.io",
      Name: "Flashcards.io",
      Desc: "The flipcard site is a study tool that allows users to create their own categories and cards, or play a trivia game already included on the site. Developed in ReactJs, NodeJs, HTML, CSS, Javascript, Redux and Firebase, the site offers a personalized learning experience, with a scoring system and social features. With a clean and user-friendly design, users can easily navigate between different sections of the website and test it on different devices and browsers.",
      Tags: ["ReactJs", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "API", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Flashcards.png",
      ProjectURL: "https://flashcards.bkappi.com",
    },
    {
      ID: "NotesApp",
      Name: "NotesApp",
      Desc: "NotesApp is an information management tool that allows users to create, organize and share notes. Developed in ReactJs, NodeJs, HTML, CSS, Javascript, Redux and Firebase, the site offers a friendly user interface and features such as attaching images and tags for organization. Users can share their notes with other users, define themselves and perform searches. With a clean and responsive design, the site works across devices and browsers.",
      Tags: ["Firebase", "UX", "JavaScript", "UI", "ReactJs", "NodeJs", "Redux", "HTML", "CSS", "API"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/NotesApp.png",
      ProjectURL: "https://notesapppro.bkappi.com",
    },
    {
      ID: "One Guitar",
      Name: "One Guitar: Assistive Technology",
      Desc: "The project consisted of a device developed in 2018 to help people with physical disabilities learn to play the guitar. Consisting of an electromechanical system that produced rhythms on the instrument and a control interface with LCD display and pedal for interaction, it was successfully tested on volunteers with different levels of musical knowledge and disabilities. The device was useful for a wide range of users with physical limitations",
      Tags: ["Engineering", "Prototyping", "Programming", "Circuit design", "Technical writing", "Project"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/OneGuitar.png",
      ProjectURL: "https://bit.ly/OneGuitarBruno",
    },
    {
      ID: "ASIS Web",
      Name: "ASIS Web",
      Desc: "In 2017, I developed a website with two colleagues to simplify the sending and receiving of notices and information for the Liberato Foundation. The project taught us valuable lessons on web development and database structuring and earned us recognition at science and technology fairs.The project consisted of a website built using HTML, CSS, Javascript, PHP, and MySQL.",
      Tags: ["HTML", "CSS", "PHP", "MySQL", "UX", "Database Modeling", "Apache Server", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Asis.jpg",
      ProjectURL: "https://bit.ly/AsisWebBruno",
    },
    {
      ID: "NotesApp Lite",
      Name: "NotesApp Lite",
      Desc: "The NotesApp Lite was created with the aim of offering users a simple and easy-to-use note-taking tool. Developed with HTML, CSS and Javascript and using localstorage for data storage, the site allows users to easily create, edit and delete notes. The clean and minimalist interface offers a distraction-free user experience, while the use of localstorage allows notes to be saved locally on the user's device. With its simplicity and functionality.",
      Tags: ["HTML", "CSS", "Javascipt", "UI", "Localstorage", "UX"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/NotesAppLite.png",
      ProjectURL: "https://notesapplite.bkappi.com",
    },
  ],

  PTBR: [
    {
      ID: "Kanban",
      Name: "Kanban",
      Desc: "Desenvolvi uma plataforma para criação e gerenciamento de quadros Kanban, utilizando tecnologias modernas como ReactJs, Vite, TailwindCss, Firebase, Redux e Typescript para garantir uma experiência fluida aos usuários. Os usuários podem personalizar colunas, adicionar tags aos cartões para organização e filtrar informações. A funcionalidade de arrastar e soltar permite mover os cartões entre colunas facilmente. Tarefas podem ser criadas dentro dos cartões, e os usuários têm controle total sobre a personalização. Compartilhamento de quadros, suporte para temas claro e escuro e integração com login do Google estão incluídos. A plataforma oferece um gerenciamento abrangente de projetos e tarefas com opções de exportação para CSV e Excel.",
      Tags: ["ReactJs", "TailwindCss", "Typescript", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Kanban.png",
      ProjectURL: "https://kanban.bkappi.com",
    },
    {
      ID: "Pomodoro",
      Name: "Pomodoro",
      Desc: "Desenvolvi um Timer Pomodoro completo, inspirado na técnica Pomodoro, para ajudar na gestão do tempo e aumentar a produtividade. O site permite personalizar as configurações, como a duração dos períodos de trabalho e descanso, e oferece a opção de receber notificações. Além disso, os usuários podem criar uma conta para armazenar suas configurações e histórico de uso na nuvem, facilitando o acesso consistente em qualquer dispositivo, e acompanhar seu progresso ao longo do tempo.",
      Tags: ["ReactJs", "TailwindCss", "Typescript", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/PomodoroThumb.png",
      ProjectURL: "https://pomodoro.bkappi.com",
    },
    {
      ID: "Bkappi",
      Name: "Bkappi",
      Desc: "Desenvolvi um Blog pessoal para publicar artigos relacionados a Desenvolvimento de Software, Segurança da Informação, Gestão de Projetos, Automação Industrial e qualquer outro assunto que possa ser útil para todo e qualquer desenvolvedor. O nome 'Bkappi' é um jogo de palavras, com o 'B' de Bruno e Kappi do meu próprio sobrenome, porém o jogo de palavras está no som do resultado final, lembrando a palavra 'Backup', que faz mais sentido na forma que é pronunciada em português, sendo muito parecida com Bkappi.",
      Tags: ["WordPress", "HTML", "CSS", "PHP", "MySQl"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Blog.png",
      ProjectURL: "https://bkappi.com",
    },
    {
      ID: "AssetSense",
      Name: "AssetSense",
      Desc: "Desenvolvi o projeto AssetSense, um software de Gestão de Ativos customizável para empresas. Utilizando React, JavaScript, CSS, HTML, Firebase e Redux, o sistema permite cadastrar, organizar e rastrear ativos por local, tipo e outros critérios. Além disso, os usuários podem personalizar o sistema adicionando novos tipos de ativos, locais, campos personalizados, permissões e outras configurações específicas. Com a capacidade de registrar retiradas e devoluções, o AssetSense gera dashboards com informações valiosas sobre ativos, usuários e sua utilização. É uma solução abrangente e flexível para a gestão eficiente de ativos empresariais.",
      Tags: ["ReactJs", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/AssetSense.png",
      ProjectURL: "https://assetsenseplatform.bkappi.com",
    },
    {
      ID: "ServiceIT",
      Name: "ServiceIT",
      Desc: "Em 2022, desenvolvi o ServiceIT, uma plataforma centralizada para gerenciamento de chamados de atendimento ao cliente, personalizável com categorias, subcategorias, tipos de urgência e outras opções, tanto para a Equipe de Suporte quanto para o cliente. Fiz como meu projeto final, me surpreendendo com o resultado e desenvolvendo além das expectativas. Apliquei conhecimentos em HTML, CSS, Javascript, ReactJS, NodeJS, Redux e Firebase, além de design e planejamento. O aplicativo tem grande potencial, apesar de alguns recursos incompletos.",
      Tags: ["ReactJs", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/ServiceIT.png",
      ProjectURL: "https://serviceitplatform.bkappi.com",
    },
    {
      ID: "Flashcards.io",
      Name: "Flashcards.io",
      Desc: "O site flipcard é uma ferramenta de estudo que permite ao usuário criar suas próprias categorias e cartões, ou jogar um jogo de trivia já incluso no site. Desenvolvido em ReactJs, NodeJs, HTML, CSS, Javascript, Redux e Firebase, o site oferece uma experiência de aprendizagem personalizada, com sistema de pontuação e recursos sociais. Com um design limpo e fácil de usar, os usuários podem navegar facilmente entre diferentes seções do site e testá-lo em diferentes dispositivos e navegadores.",
      Tags: ["ReactJs", "NodeJs", "Redux", "HTML", "CSS", "Firebase", "API", "UX", "JavaScript", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Flashcards.png",
      ProjectURL: "https://flashcards.bkappi.com",
    },
    {
      ID: "NotesApp",
      Name: "NotesApp",
      Desc: "NotesApp é uma ferramenta de gerenciamento de informações que permite aos usuários criar, organizar e compartilhar notas. Desenvolvido em ReactJs, NodeJs, HTML, CSS, Javascript, Redux e Firebase, o site oferece interface amigável e recursos como anexar imagens e tags para organização. Os usuários podem compartilhar suas anotações com outros usuários, definir-se e realizar pesquisas. Com um design limpo e responsivo, o site funciona em vários dispositivos e navegadores.",
      Tags: ["Firebase", "UX", "JavaScript", "UI", "ReactJs", "NodeJs", "Redux", "HTML", "CSS", "API"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/NotesApp.png",
      ProjectURL: "https://notesapppro.bkappi.com",
    },
    {
      ID: "One Guitar",
      Name: "One Guitar: Tecnologia Assistiva",
      Desc: "O projeto consistiu em um dispositivo desenvolvido em 2018 para ajudar pessoas com deficiência física a aprender a tocar violão. Composto por um sistema eletromecânico que produzia ritmos no instrumento e uma interface de controle com display LCD e pedal para interação, foi testado com sucesso em voluntários com diferentes níveis de conhecimento musical e deficiência. O dispositivo foi útil para uma ampla gama de usuários com limitações físicas",
      Tags: ["Engineering", "Prototyping", "Programming", "Circuit design", "Technical writing", "Project"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/OneGuitar.png",
      ProjectURL: "https://bit.ly/OneGuitarBruno",
    },
    {
      ID: "ASIS Web",
      Name: "ASIS Web",
      Desc: "Em 2017, desenvolvi um site com dois colegas para simplificar o envio e recebimento de comunicados e informações para a Fundação Liberato. O projeto nos ensinou lições valiosas sobre desenvolvimento web e estruturação de banco de dados e nos rendeu reconhecimento em feiras de ciência e tecnologia. O projeto consistia em um site construído em HTML, CSS, Javascript, PHP e MySQL.",
      Tags: ["HTML", "CSS", "PHP", "MySQL", "UX", "Database Modeling", "Apache Server", "UI"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/Asis.jpg",
      ProjectURL: "https://bit.ly/AsisWebBruno",
    },
    {
      ID: "NotesApp Lite",
      Name: "NotesApp Lite",
      Desc: "O NotesApp Lite foi criado com o objetivo de oferecer aos usuários uma ferramenta de anotações simples e fácil de usar. Desenvolvido com HTML, CSS e Javascript e usando localstorage para armazenamento de dados, o site permite que os usuários criem, editem e excluam notas com facilidade. A interface limpa e minimalista oferece uma experiência de usuário livre de distrações, enquanto o uso de localstorage permite que as anotações sejam salvas localmente no dispositivo do usuário. Com sua simplicidade e funcionalidade.",
      Tags: ["HTML", "CSS", "Javascipt", "UI", "Localstorage", "UX"],
      ThumbURL: "https://bkappi.com/Images/Portfolio/ProjectsThumbs/NotesAppLite.png",
      ProjectURL: "https://notesapplite.bkappi.com",
    },
  ],
};

export const GetProjects = () => {
  let formattedString = "My Projects: ";
  ProjectsData.En.forEach((Project, index) => {
    formattedString += `Project ${index + 1}:`;
    formattedString += `Name: ${Project.Name}`;
    formattedString += `Description: ${Project.Desc}`;
  });

  return formattedString;
};
