import React, { useState, useEffect } from "react";
import "./ScrollTop.css"; // Import CSS styles for the button
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { FaLongArrowAltDown } from "react-icons/fa";
import { Evento } from "../firebase/metodos";
import { EventsType } from "../../data/Events";

function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show the button when the user has scrolled down 100 pixels
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    Evento(EventsType.Geral, "ToUp");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <button className={`scroll-to-top-button bounce ${isVisible ? "visible" : ""}`} onClick={handleClick}>
        <BsFillArrowUpCircleFill />
      </button>

      <button className={`scroll-to-down-button  bounce ${!isVisible ? "visible" : ""}`} onClick={(e) => Evento(EventsType.Geral, "ToDown")}>
        <FaLongArrowAltDown />
      </button>
    </>
  );
}

export default ScrollTop;
