// ############### CONTACT OPTIONS ############### //

export const ContactWords = {
  En: {
    Title: "Contact",
    SentMessage: "Email successfully sent!",
    EMailPlaceholder: "Type your Email",
    NomePlaceholder: "Type your Name",
    MensagemPlaceholder: "Type your Message",
    ButtonSend: "Send",
  },
  PTBR: {
    Title: "Contato",
    SentMessage: "Email enviado com Sucesso!",
    EMailPlaceholder: "Digite seu Email",
    NomePlaceholder: "Digite seu Nome",
    MensagemPlaceholder: "Digite sua mensagem",
    ButtonSend: "Enviar",
  },
};
