// ############### INTRODUCTION ############### //

import { Idade } from "./utils";

export const IntroductionText = {
  En: {
    Hi: "Hi, my name is",
    Introduction1: "I am trying my very best.",
    Introduction2: "I am a Systems Analyst and web developer.",
    Introduction3: "I am a Systems Analyst and web developer. I have " + Idade("17/10/2016") + " years of experience in Web Development. " + Idade("17/03/2019") + " years in Industrial Automation and systems development, which has allowed me to become a highly skilled professional in both areas.",
    Introduction4: "I'm a Systems Analyst and web developer. I have " + Idade("17/10/2016") + " years of experience in Web Development. " + Idade("17/03/2019") + " years in Industrial Automation, which allowed me to become a highly qualified professional in both areas. I completed three degrees: Electronics Technician , Systems Analysis and Development and Process Management.",
    Introduction5: "I'm a Systems Analyst and web developer. I have " + Idade("17/10/2016") + " years of experience in Web Development. " + Idade("17/03/2019") + " years in Industrial Automation, which allowed me to become a highly qualified professional in both areas. I completed three degrees: Electronics Technician , Systems Analysis and Development and Process Management. My work experience includes developing, maintaining, improving and managing information systems projects and MES Systems.",
    Introduction6: "I'm a Systems Analyst and web developer. I have " + Idade("17/10/2016") + " years of experience in Web Development. " + Idade("17/03/2019") + " years in Industrial Automation, which allowed me to become a highly qualified professional in both areas. I completed three degrees: Electronics Technician , Systems Analysis and Development and Process Management. My work experience includes developing, maintaining, improving and managing information systems projects and MES Systems. I Am also proficient in web development, with extensive knowledge of languages such as ReactJS, NodeJS, HTML, CSS, PHP, JavaScript, typescript, tailwindcss, among others. I live in Novo Hamburgo, RS, Brazil.",
    IntroductionOptions: "Adjust bio length:",
  },
  PTBR: {
    Hi: "Olá, eu me chamo",
    Introduction1: "Estou dando o meu melhor.",
    Introduction2: "Sou Analista de Sistemas e desenvolvedor Web.",
    Introduction3: "Sou Analista de Sistemas e desenvolvedor Web. Tenho " + Idade("17/10/2016") + " anos de experiência em Desenvolvimento Web. " + Idade("17/03/2019") + " anos em Automação Industrial e desenvolvimento de sistemas, o que permitiu-me tornar um profissional altamente qualificado em ambas as áreas.",
    Introduction4: "Sou Analista de Sistemas e desenvolvedor Web. Tenho " + Idade("17/10/2016") + " anos de experiência em Desenvolvimento Web. " + Idade("17/03/2019") + " anos em Automação Industrial e desenvolvimento de sistemas, o que me permitiu me tornar um profissional altamente qualificado em ambas as áreas. Concluí três graduações: Técnico em Eletrônica, Análise e Desenvolvimento de Sistemas e Processos Gerenciais.",
    Introduction5: "Sou Analista de Sistemas e desenvolvedor Web. Tenho " + Idade("17/10/2016") + " anos de experiência em Desenvolvimento Web. " + Idade("17/03/2019") + " anos em Automação Industrial e desenvolvimento de sistemas, o que me permitiu me tornar um profissional altamente qualificado em ambas as áreas. Concluí três graduações: Técnico em Eletrônica, Análise e Desenvolvimento de Sistemas e Processos Gerenciais. Minha experiência de trabalho inclui o desenvolvimento, manutenção, melhoria e gerenciamento de projetos de sistemas de informação e Sistemas MES.",
    Introduction6: "Sou Analista de Sistemas e desenvolvedor Web. Tenho " + Idade("17/10/2016") + " anos de experiência em Desenvolvimento Web. " + Idade("17/03/2019") + " anos em Automação Industrial e desenvolvimento de sistemas, o que me permitiu me tornar um profissional altamente qualificado em ambas as áreas. Concluí três graduações: Técnico em Eletrônica, Análise e Desenvolvimento de Sistemas e Processos Gerenciais. Minha experiência de trabalho inclui o desenvolvimento, manutenção, melhoria e gerenciamento de projetos de sistemas de informação e Sistemas MES. Também sou proficiente em desenvolvimento web, com amplo conhecimento de linguagens como ReactJS, NodeJS, HTML, CSS, PHP, JavaScript, typescript, tailwindCss, entre outras. Moro em Novo Hamburgo, RS, Brasil.",
    IntroductionOptions: "Ajuste o tamanho da bio:",
  },
};
