// ############### EXPERIENCE ############### //

export const ExperienceSectionData = {
  En: {
    Title: "Where I've Worked",
  },
  PTBR: {
    Title: "Experiência Profissional",
  },
};

export const ExperienceData = {
  En: [
    {
      ID: "SerranoLInk",
      CompanyPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Serrano.jpg",
      CompanyName: "Serrano Automação",
      RoleName: "Systems Analyst",
      Period: "Mai 2023 - Present",
      Location: "Porto Alegre, Brazil",
      Desc: "Working in the Information Systems sector, focusing on the development and specification of solutions for process optimization. My responsibilities range from conception to system implementation, requiring technical, analytical, and adaptive skills. This includes requirement analysis, project design, team coordination, and implementation of systems that precisely meet organizational needs. The ability to work in multifunctional environments and communicate effectively is crucial for success in this role.",
      URL: "https://bit.ly/SerranoAut",
      Tags: ["Project Management", "Project Especification", "Software Development", "Front-End Devlopment", "Back-End Devlopment", "ReactJs", "Tyepscript", "Tailwindcss", "Web Services", "Middlewares", "Microsoft SQL Server", "MES Systems", "SQL Reporting", "Power BI", "Processes design", ".NET"],
    },
    {
      ID: "SerranoLInk",
      CompanyPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Serrano.jpg",
      CompanyName: "Serrano Automação",
      RoleName: "Support, Maintenance, and MES Systems Development",
      Period: "Mar 2019 - Mai 2023",
      Location: "Porto Alegre, Brazil",
      Desc: "Working with Support, Maintenance and Development with a Manufacturing Execution System (MES) with tools from Wonderware and Schneider Electric. Acting also in the layers of communication, data historization, webservices and database. In addition, working with the Intouch supervisor and analyzing PLC's logic as a data source for the system. This job requires a lot of organization to manage support / development demands and a great analytical capacity for problem solving and projection of continuous improvement.",
      URL: "https://bit.ly/SerranoAut",
      Tags: ["Technical Support", "Industrial Automation", "Project Management", "Web Services", "Microsoft SQL Server", "Application Server", "MES", "Archestra", "System Platform", "Middleware ", "Database Administration", "SQL Reporting", "Intouch", ".NET"],
    },
  ],
  PTBR: [
    {
      ID: "SerranoLInk",
      CompanyPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Serrano.jpg",
      CompanyName: "Serrano Automação",
      RoleName: "Analista de Sistemas",
      Period: "Maio 2023 - Presente",
      Location: "Porto Alegre, Brasil",
      Desc: "Trabalhando no setor de Sistemas de Informação, focando no desenvolvimento e especificação de soluções para otimização de processos. Minhas responsabilidades vão desde a concepção até a implementação de sistemas, exigindo habilidades técnicas, analíticas e adaptativas. Isso inclui análise de requisitos, elaboração de projetos, coordenação com equipes e implementação de sistemas que atendam precisamente às necessidades da organização. A capacidade de trabalhar em ambientes multifuncionais e comunicar efetivamente é fundamental para o sucesso nesse papel.",
      URL: "https://bit.ly/SerranoAut",
      Tags: ["Gestão de Projetos", "Especificação de Projetos", "Desenvolvimento de Software", "Desenvolvimento Front-End", "Desenvolvimento Back-End", "ReactJs", "Tyepscript", "Tailwindcss", "Serviços Web", "Middlewares", "Microsoft SQL Server", "Sistemas MES", "Relatórios SQL", "Power BI", "Design de Processos", ".NET"],
    },
    {
      ID: "SerranoLInk",
      CompanyPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Serrano.jpg",
      CompanyName: "Serrano Automação",
      RoleName: "Suporte, Manutenção e Desenvolvimento de Sistemas MES",
      Period: "Mar 2019 - Mai0 2023",
      Location: "Porto Alegre, Brasil",
      Desc: "Trabalhando com suporte, manutenção e desenvolvimento de um sistema MES. Minhas responsabilidades incluíam desenvolvimento de scripts, telas, procedures, comunicação, historização de dados, webservices e banco de dados, além de analisar lógicas de PLCs como fonte de dados. Precisando ser altamente organizado para gerenciar as demandas de suporte/desenvolvimento e habilidoso na análise para resolver problemas e projetar melhorias contínuas.",
      URL: "https://bit.ly/SerranoAut",
      Tags: ["Suporte Técnico", "Automação Industrial", "Gerenciamento de Projetos", "Serviços Web", "Microsoft SQL Server", "Servidor de Aplicativos", "MES (Manufacturing Execution System)", "Archestra", "Plataforma de Sistemas", "Middleware", "Administração de Bancos de Dados", "Relatórios SQL", "Intouch", ".NET"],
    },
  ],
};

export const GetWorkExperience = () => {
  let formattedString = "Professional Experience: ";
  ExperienceData.En.forEach((experience, index) => {
    formattedString += `Experience ${index + 1}:`;
    formattedString += `Company: ${experience.CompanyName}`;
    formattedString += `Role: ${experience.RoleName}`;
    formattedString += `Period: ${experience.Period}`;
    formattedString += `Location: ${experience.Location}`;
    formattedString += `Description: ${experience.Desc} `;
  });

  return formattedString;
};
