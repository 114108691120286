import React, { useState, useEffect } from "react";
import { AtualizarUso, DeletarUso, getUsos } from "../firebase/metodos";
import Accordion from "react-bootstrap/Accordion";
import "./Dashboard.css";
import store from "../../store/store";
import { v4 } from "uuid";

const Dashboard = () => {
  const [Password, setPassword] = useState("");

  useEffect(() => {
    const Saved = localStorage.getItem("DashboardInput");
    if (Saved) {
      setPassword(Saved);
    }
  }, []);

  useEffect(() => {
    if (Password === process.env.REACT_APP_PASSWORD) {
      localStorage.setItem("DashboardInput", Password);
    } 
  }, [Password]);

  const DeleteUso = (docID) => {
    DeletarUso(docID).then((res) => {
      getUsos().then((res) => {
        //console.log(res)
        setUsos([...res]);
      });
    });
  };

  const UpdateUsoTag = (Uso) => {
    const NewUso = JSON.parse(JSON.stringify(Uso));

    NewUso.Tag = localStorage.getItem(Uso.docID);

    AtualizarUso(NewUso).then(() => {
      getUsos().then((res) => {
        setUsos([...res]);
      });
    });
  };

  const [Usos, setUsos] = useState([]);

  useEffect(() => {
    getUsos().then((res) => {
      //console.log(res)
      setUsos([...res]);
    });
  }, []);

  const SortUsos = (a, b) => {
    return new Date(b.Geral.Data) - new Date(a.Geral.Data);
  };

  return (
    <div className="Dashboard">
      <h1>Usos</h1>

      <input className="DashboardInput" value={Password} onChange={(e) => setPassword(e.target.value)}></input>

      {process.env.REACT_APP_PASSWORD === Password && (
        <Accordion>
          {Usos.sort(SortUsos).map((Uso, index) => {
            // Crie um objeto de data a partir da string de data
            const date = new Date(Uso.Geral.Data);
            date.setHours(date.getHours() - 3);

            // Use o método getUTCDate para obter o dia do mês, o método getUTCMonth para obter o mês (que começa em 0), e o método getUTCFullYear para obter o ano
            const day = date.getUTCDate();
            const month = date.getUTCMonth() + 1; // Adicione 1 ao mês porque ele começa em 0
            const year = date.getUTCFullYear();

            // Use o método getUTCHours para obter a hora e o método getUTCMinutes para obter os minutos
            const hours = date.getUTCHours();
            const minutes = date.getUTCMinutes();

            // Use a função padStart para adicionar um zero à esquerda do dia, mês, hora e minuto, caso eles tenham apenas um dígito
            const formattedDate = `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year.toString().slice(-2)} ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

            //console.log("USO", Uso);

            return (
              <Accordion.Item eventKey={index} key={Uso.Fingerprint}>
                <Accordion.Header className="UsosAccordionHeader">
                  <span>{"Uso " + (index + 1) + " - " + formattedDate}</span>
                  <span className="HeadTag">{Uso?.Tag ? Uso?.Tag : "Anônimo"}</span>
                </Accordion.Header>
                <Accordion.Body>
                  {Object.keys(Uso).map((Topico) => {
                    if (Topico === "docID" || Topico === "Fingerprint" || Topico === "Language" || Topico === "Tag" || Topico === "GPTSearch") return;

                    if (Topico === "Vezes")
                      return (
                        <div className="UsoHeader">
                          <span>Vezes</span>
                          <div className="UsosDiv">
                            <span className="Uso">
                              <div className="UsoName">Vezes:</div> <div className={"UsoValue "}>{Uso.Vezes}</div>
                            </span>
                          </div>
                        </div>
                      );

                    if (Object.keys(Uso[Topico]) <= 0) return;

                    return (
                      <div className="UsoHeader">
                        <span>{Topico}</span>
                        <div className="UsosDiv">
                          {Uso[Topico] &&
                            Object.keys(Uso[Topico]).map((Item) => {
                              return (
                                <span className="Uso">
                                  <div className="UsoName">{Item}:</div> <div className={"UsoValue "}>{Uso[Topico][Item]}</div>
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="UsoHeader">
                  <span>Geral</span>

                  <div className="UsosDiv">
                    <span className="Uso">
                      <div className="UsoName">Inner:</div> <div className={"UsoValue "}>{Uso.innerWidth}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Outer:</div> <div className={"UsoValue "}> {Uso.outerWidth}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Navegador:</div> <div className={"UsoValue "}> {Uso.Navegador}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Location:</div> <div className={"UsoValue "}> {Uso.Location}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Data:</div> <div className={"UsoValue "}> {formattedDate}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Language:</div> <div className={"UsoValue "}> {Uso.Language}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">LanguageChgange:</div> <div className={Uso.LanguageChange > 0 ? "Mudou" : "NãoMudou"}> {Uso.LanguageChange}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Up:</div> <div className={Uso.ToUp > 0 ? "Mudou" : "NãoMudou"}> {Uso.ToUp}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Down:</div> <div className={Uso.ToDown > 0 ? "Mudou" : "NãoMudou"}> {Uso.ToDown}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Vezes:</div> <div className={Uso.Vezes > 0 ? "Mudou" : "NãoMudou"}> {Uso.Vezes}</div>
                    </span>
                  </div>
                </div>

                <div className="UsoHeader">
                  <span>Header</span>
                  <div className="UsosDiv">
                    <span className="Uso">
                      <div className="UsoName">Linkedin:</div> <div className={Uso.LinkedinNav > 0 ? "Mudou" : "NãoMudou"}> {Uso.LinkedinNav}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Instagram:</div> <div className={Uso.InstagramNav > 0 ? "Mudou" : "NãoMudou"}> {Uso.InstagramNav}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Facebook:</div> <div className={Uso.FacebookNav > 0 ? "Mudou" : "NãoMudou"}> {Uso.FacebookNav}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Github:</div> <div className={Uso.GithubNav > 0 ? "Mudou" : "NãoMudou"}> {Uso.GithubNav}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Resume:</div> <div className={Uso.Resume > 0 ? "Mudou" : "NãoMudou"}> {Uso.Resume}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">LanguageChgange:</div> <div className={Uso.LanguageChange > 0 ? "Mudou" : "NãoMudou"}> {Uso.LanguageChange}</div>
                    </span>

                    <span className="Uso">
                      <div className="UsoName">About:</div> <div className={Uso.AboutTab > 0 ? "Mudou" : "NãoMudou"}> {Uso.AboutTab}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Projects:</div> <div className={Uso.ProjectsTab > 0 ? "Mudou" : "NãoMudou"}> {Uso.ProjectsTab}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Experience:</div> <div className={Uso.ExperienceTab > 0 ? "Mudou" : "NãoMudou"}> {Uso.ExperienceTab}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Academic:</div> <div className={Uso.AcademicTab > 0 ? "Mudou" : "NãoMudou"}> {Uso.AcademicTab}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Contact:</div> <div className={Uso.ContactTab > 0 ? "Mudou" : "NãoMudou"}> {Uso.ContactTab}</div>
                    </span>
                  </div>
                </div>

                <div className="UsoHeader">
                  <span>Introduction</span>
                  <div className="UsosDiv">
                    <span className="Uso">
                      <div className="UsoName">BioAjust:</div> <div className={Uso.BioAjust > 0 ? "Mudou" : "NãoMudou"}> {Uso.BioAjust}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">ChatGPT:</div> <div className={Uso.ChatGPTSearch > 0 ? "Mudou" : "NãoMudou"}> {Uso.ChatGPTSearch}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">ChatGPTSelect:</div> <div className={Uso.ChatGPTSearchSelected > 0 ? "Mudou" : "NãoMudou"}> {Uso.ChatGPTSearchSelected}</div>
                    </span>
                  </div>
                </div>

                <div className="UsoHeader">
                  <span>Links</span>
                  <div className="UsosDiv">
                    <span className="Uso">
                      <div className="UsoName">ServiceIt:</div> <div className={Uso.ServiceIt > 0 ? "Mudou" : "NãoMudou"}> {Uso.ServiceIt}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">AssetSense:</div> <div className={Uso.AssetSense > 0 ? "Mudou" : "NãoMudou"}> {Uso.AssetSense}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Flashcards:</div> <div className={Uso.Flashcards > 0 ? "Mudou" : "NãoMudou"}> {Uso.Flashcards}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">NotesApp:</div> <div className={Uso.NotesApp > 0 ? "Mudou" : "NãoMudou"}> {Uso.NotesApp}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">NotesAppLite:</div> <div className={Uso.NotesAppLite > 0 ? "Mudou" : "NãoMudou"}> {Uso.NotesAppLite}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Oneguitar:</div> <div className={Uso.Oneguitar > 0 ? "Mudou" : "NãoMudou"}> {Uso.Oneguitar}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Asis:</div> <div className={Uso.Asis > 0 ? "Mudou" : "NãoMudou"}> {Uso.Asis}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">SerranoLink:</div> <div className={Uso.SerranoLink > 0 ? "Mudou" : "NãoMudou"}> {Uso.SerranoLink}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Unisinos:</div> <div className={Uso.Unisinos > 0 ? "Mudou" : "NãoMudou"}> {Uso.Unisinos}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Uninter:</div> <div className={Uso.Uninter > 0 ? "Mudou" : "NãoMudou"}> {Uso.Uninter}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Liberato:</div> <div className={Uso.Liberato > 0 ? "Mudou" : "NãoMudou"}> {Uso.Liberato}</div>
                    </span>
                  </div>
                </div>

                <div className="UsoHeader">
                  <span>Certifications and Courses</span>
                  <div className="UsosDiv">
                    <span className="Uso">
                      <div className="UsoName">Certification:</div> <div className={Uso.CertificationItem > 0 ? "Mudou" : "NãoMudou"}> {Uso.CertificationItem}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Course:</div> <div className={Uso.CourseItem > 0 ? "Mudou" : "NãoMudou"}> {Uso.CourseItem}</div>
                    </span>
                  </div>
                </div>

                <div className="UsoHeader">
                  <span>Contact</span>
                  <div className="UsosDiv">
                    <span className="Uso">
                      <div className="UsoName">Linkedin:</div> <div className={Uso.LinkedinContact > 0 ? "Mudou" : "NãoMudou"}> {Uso.LinkedinContact}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Instagram:</div> <div className={Uso.InstagramContact > 0 ? "Mudou" : "NãoMudou"}> {Uso.InstagramContact}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Facebook:</div> <div className={Uso.FacebookContact > 0 ? "Mudou" : "NãoMudou"}> {Uso.FacebookContact}</div>
                    </span>
                    <span className="Uso">
                      <div className="UsoName">Github:</div> <div className={Uso.GithubContact > 0 ? "Mudou" : "NãoMudou"}> {Uso.GithubContact}</div>
                    </span>
                  </div>
                </div> */}

                  {Uso?.GPTSearch?.map((Pesquisa, Index) => {
                    return (
                      <div className="UsoHeader" key={v4()}>
                        <span>
                          {Index + 1} {")"} Pesquisa feita no ChatGPT
                        </span>
                        <div className="UsosDiv">
                          <span className="Uso">
                            <div className="UsoName">{Pesquisa?.Promt}:</div> <div className={"UsoValue "}>{Pesquisa?.Reponse}</div>
                          </span>
                        </div>
                      </div>
                    );
                  })}

                  <div className="TagDiv">
                    <input className="ChatGPTSearch" onChange={(e) => localStorage.setItem(Uso.docID, e.target.value)}></input>
                  </div>

                  <div className="ButtonsDiv">
                    <button className="DeleteButton" onClick={(e) => DeleteUso(Uso.docID)}>
                      Deletar Uso
                    </button>
                    <button className="DeleteButton" onClick={(e) => UpdateUsoTag(Uso)}>
                      Salvar
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
    </div>
  );
};

export default Dashboard;
