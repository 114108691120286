
export const setLanguage = (Language) => {
    return ({
        type: 'SET_LANGUAGE',
        Language
    })
} 

export const setUser = (user) => {
    return ({
        type: 'SET_USER',
        user
    })
}

export const setUso = (Uso) => {
    return ({
        type: 'SET_USO',
        Uso
    })
}

export const setUsoSaved = (UsoSaved) => {
    return ({
        type: 'SET_USOSAVED',
        UsoSaved
    })
}


export const setSection = (Section) => {
    return ({
        type: 'SET_SECTION',
        Section
    })
}




