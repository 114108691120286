import { db } from "../../components/firebase/index";
import { collection } from "firebase/firestore";
import { getDocs, addDoc, updateDoc, deleteDoc, doc, where, query } from "firebase/firestore";
import store from "../../store/store";
import { setUso, setUsoSaved } from "../../store/actions/UserActions";
import { getRawFingerprint } from "react-fingerprint";
import { hashString } from "react-hash-string";
import { EventsType } from "../../data/Events";

export var UsosCollectionRef = collection(db, "Usos");

const DefaultUso = {
  Vezes: 1,
  Fingerprint: "",
  docID: "",
  AboutTab: 0,
  ProjectsTab: 0,
  ExperienceTab: 0,
  Academic: 0,
  ContactTab: 0,
  Resume: 0,
  LinkedinNav: 0,
  GithubNav: 0,
  FacebookNav: 0,
  InstagramNav: 0,
  LinkedinContact: 0,
  GithubContact: 0,
  FacebookContact: 0,
  InstagramContact: 0,
  BioAjust: 0,
  ChatGPTSearch: 0,
  ChatGPTSearchSelected: 0,
  ServiceIt: 0,
  Flashcards: 0,
  NotesApp: 0,
  Oneguitar: 0,
  Asis: 0,
  NotesAppLite: 0,
  SerranoLink: 0,
  Unisinos: 0,
  Uninter: 0,
  Liberato: 0,
  ToUp: 0,
  ToDown: 0,
  innerWidth: 0,
  outerWidth: 0,
  Language: "Eng",
  Location: "",
  CertificationItem: 0,
  CourseItem: 0,
  LanguageChange: 0,
  Navegador: "",
  NavegadorLingua: "",
  Data: "",
  Tag: "Anônimo",
};

export const AdicionarUso = async (Uso) => {
  await getRawFingerprint().then((r) => {
    //const Finger = r.webgl.renderer + window.outerWidth + r.browser.app.codename + r.browser.userAgent
    const Finger = JSON.stringify(r);
    Uso.Fingerprint = hashString(Finger).toString();
  });

  const colecao = "Usos";
  UsosCollectionRef = collection(db, colecao);
  return addDoc(UsosCollectionRef, Uso);
};

export const AtualizarUso = async (EditedUso) => {
  const colecao = "Usos";
  UsosCollectionRef = collection(db, colecao);
  const UsoDoc = doc(db, colecao, EditedUso.docID);
  const newFields = { ...EditedUso };
  return updateDoc(UsoDoc, newFields);
};

export const DeletarUso = async (docID) => {
  const userRef = doc(db, "Usos", docID);
  return deleteDoc(userRef);
};

export const getUsos = async () => {
  const colecao = "Usos";
  UsosCollectionRef = collection(db, colecao);
  const data = await getDocs(UsosCollectionRef);
  const dados = data.docs.map((doc) => ({ ...doc.data(), docID: doc.id }));
  //SetUsos(dados)
  ////console.log(dados)
  return dados;
};

export const getUso = async (Fingerprint) => {
  //console.log(Fingerprint.toString())
  const colecao = "Usos";
  UsosCollectionRef = collection(db, colecao);
  const data = await getDocs(UsosCollectionRef);
  //const dados = data.docs.map((doc) => ({ ...DefaultUso, ...doc.data(), docID: doc.id }))

  //QUERY
  const q = query(UsosCollectionRef, where("Fingerprint", "==", Fingerprint.toString()));

  const querySnapshot = await getDocs(q);
  const dados2 = querySnapshot.docs.map((doc) => {
    return { ...doc.data(), docID: doc.id };
  });

  if (dados2.length > 0) return dados2[0];
  else return {};
  //QUERY
};

export const Evento = async (Evento, Value) => {
  var Uso = { ...store.getState().User.Uso };
  var Saved = store.getState().User.UsoSaved;
  var Language = store.getState().User.Language;

  if (Evento === "docID") Uso.docID = Value;

  if (Evento === EventsType.Projects) Uso.Projects[Value] = (Uso.Projects[Value] || 0) + 1;
  if (Evento === EventsType.SocialNav) Uso.SocialNav[Value] = (Uso.SocialNav[Value] || 0) + 1;
  if (Evento === EventsType.Navbar) Uso.Navbar[Value] = (Uso.Navbar[Value] || 0) + 1;
  if (Evento === EventsType.Experience) Uso.Experience[Value] = (Uso.Experience[Value] || 0) + 1;
  if (Evento === EventsType.Introduction) Uso.Introduction[Value] = (Uso.Introduction[Value] || 0) + 1;
  if (Evento === EventsType.Academic) Uso.Academic[Value] = (Uso.Academic[Value] || 0) + 1;
  if (Evento === EventsType.Certifications) Uso.Certifications[Value] = (Uso.Certifications[Value] || 0) + 1;
  if (Evento === EventsType.Courses) Uso.Courses[Value] = (Uso.Courses[Value] || 0) + 1;
  if (Evento === EventsType.Contact) Uso.Contact[Value] = (Uso.Contact[Value] || 0) + 1;
  if (Evento === EventsType.Geral) Uso.Geral[Value] = (Uso.Geral[Value] || 0) + 1;
  if (Evento === EventsType.GPTSearch) Uso.GPTSearch = [...Uso.GPTSearch, Value];

  //console.log("EVENTO", Uso);

  Uso.Geral.Data = Date();
  Uso.Geral.NavegadorLingua = navigator.language;
  Uso.Geral.Navegador = navigator.userAgent;
  Uso.Geral.innerWidth = window.innerWidth;
  Uso.Geral.outerWidth = window.outerWidth;
  Uso.Geral.Language = Language;
  Uso.Geral.Location = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (Saved) {
    AtualizarUso(Uso);
  } else {
    AdicionarUso(Uso)
      .then((docRef) => {
        Uso.docID = docRef.id;
        store.dispatch(setUsoSaved(true));
      })
      .catch((error) => {});
  }

  store.dispatch(setUso(Uso));
};
