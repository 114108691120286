import { combineReducers, createStore } from "redux";
import User from "./reducers/User";

const store = createStore(
  combineReducers({
    User,
  }),

  //A linha abaixo é para habilitar o Rexux Store Extension
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
  //localStorage.setItem("FlashCardsCategories", JSON.stringify(store.getState().Cards))
  //localStorage.setItem("FlashCardsUserInfo", JSON.stringify(store.getState().User))
});

export default store;
