import React, { useEffect, useState, useRef } from "react";
import TagsCloud from "../tagsCloud/TagsCloud";
import "./About.css";
import { connect } from "react-redux";
import { AboutText, AboutWords } from "../../data/About";

const getTranslation = (Language, Text) => {
  return AboutText[Language][Text];
};

const About = (props) => {
  const myRef = useRef(null);

  const [phraseIndex, setPhraseIndex] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState("");

  const getPhrasesArray = (Language) => {
    return AboutWords[Language];
  };

  useEffect(() => {
    const CurrentPhrases = getPhrasesArray(props.User.Language);
    const phrase = CurrentPhrases[phraseIndex];
    const intervalId = setInterval(() => {
      setCurrentPhrase((prev) => {
        if (prev === phrase) {
          setPhraseIndex(
            (prevIndex) => (prevIndex + 1) % CurrentPhrases.length
          );
          return "";
        } else {
          return phrase.slice(0, prev.length + 1);
        }
      });
    }, 200);
    return () => clearInterval(intervalId);
  }, [phraseIndex]);

  useEffect(() => {
    if (props.User.Section === "About") {
      if (window.innerWidth < 1000) {
        const id = "About";
        const yOffset = -500;
        const element = document.getElementById(id);
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [props.User.Section]);

  return (
    <div ref={myRef} id="About" className="AboutContainer">
      <h1 className="AboutTitle">
        {getTranslation(props.User.Language, "Title")}
      </h1>
      <div className="Divider">
        <div></div>
      </div>
      <div className="About">
        <div className="AboutText">
          <div className="AboutDescription">
            <div className="AboutName">Get to know me!</div>
            <div>{getTranslation(props.User.Language, "Desc1")}</div>
            <div>{getTranslation(props.User.Language, "Desc2")}</div>
            <div>{getTranslation(props.User.Language, "Desc3")}</div>
            <div className="SoftSkills">{currentPhrase}</div>
          </div>
        </div>
        <div>
          <h1 className="SkillsTitle">Skills</h1>
          <TagsCloud />
        </div>
      </div>
    </div>
  );
};

const ConnectedAbout = connect((state) => {
  return {
    User: state.User,
  };
})(About);

export default ConnectedAbout;
