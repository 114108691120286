const UserDefault = {
  Language: "En",
  Section: "",
  UsoSaved: false,
  Uso: {
    Vezes: 1,
    Fingerprint: "",
    docID: "",
    Tag: "Anônimo Automático",
    Geral: {
      ToUp: 0,
      ToDown: 0,
      innerWidth: 0,
      outerWidth: 0,
      Language: "Eng",
      Location: "",
      LanguageChange: 0,
      Navegador: "",
      NavegadorLingua: "",
      Data: "",
    },
    Projects: {},
    SocialNav: {},
    Navbar: {},
    Introduction: {},
    Experience: {},
    Academic: {},
    Certifications: {},
    Courses: {},
    Contact: {},
    GPTSearch: [],
  },
};

const User = (state = UserDefault, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        Language: action.Language,
      };
    case "SET_SECTION":
      return {
        ...state,
        Section: action.Section,
      };
    case "SET_USO":
      return {
        ...state,
        Uso: action.Uso,
      };
    case "SET_USOSAVED":
      return {
        ...state,
        UsoSaved: action.UsoSaved,
      };
    case "SET_USER":
      return action.user;
    default:
      return state;
  }
};

export default User;
