// ############### GPT ############### //

import { GetAcademic } from "./Academic";
import { GetCertifications } from "./Certifications";
import { GetCourses } from "./Courses";
import { GetWorkExperience } from "./Experience";
import { GetProjects } from "./Projects";
import { GetSkills } from "./TagCloud";
import { Idade, obterDataAtual } from "./utils";

export const GPTPhrases = {
  En: ["Skills", "Experience", "About", "Current Work", "Age", "Location", "Projects"],
  PTBR: ["Habilidades", "Experiência", "Sobre", "Emprego atual", "Idade", "Localidade", "Projetos"],
};

export const GPTSection = {
  En: {
    Ask: "Ask anything about Bruno (AI Generated)",
    SearchButton: "Search",
    AnswerLanguage: ".Your Answer MUST BE in English",
  },
  PTBR: {
    Ask: "O que deseja saber sobre Bruno (Gerado por IA)",
    SearchButton: "Buscar",
    AnswerLanguage: ".Your Answer MUST BE in Portuguese",
  },
};

export const GPT_Text_Fonte = `For context, today is ${obterDataAtual()}: Name: Bruno Kappi. Age:${Idade("17/10/1999")}
.Location: Novo Hamburgo, RS, Brazil. Hobbies: Web programming, Playing musical instruments, Electronics and robotics. 
Email: brunokappidematos2@gmail.com. Phone(WhatsApp):(+55)51994635740. ${GetAcademic()}. ${GetWorkExperience()} . ${GetCourses()}
In the work that I develop, I work a lot with low-level programming such as VB.NET, QuickScript, and .NET but also with SQL Server database modeling and management and development and the creation of reports made in SQL Reporting Services.My current job involves developing, maintaining, and improving a Manufacturing Execution System (MES) for the largest polymer producer in Brazil, specifically for the bagging stage. The system manages and controls all routines and the quantity of product being exported. I use the Wonderware platform, including the System Platform, Application System, and Intouch for system platform, and manages the database on the SQL server and generates reports through SQL reporting Services in Visual Studio. I work with programming languages such as VB.NET, QuickScript, and .NET, and is also responsible for creating interfaces with historical systems and SAP.In addition to my work experience, I also worked in the project sector of my company. In this sector I participated in some conceptual projects and systems implementation projects.I have extensive experience with both Wonderware Application Server and InTouch for System Platform. In my current role as a Control and Automation Technician, I work with a Manufacturing Execution System (MES) using tools from Wonderware and Schneider Electric. I am responsible for providing support, maintenance, and development for MES systems. Additionally. My experience 
with both Wonderware Application Server and InTouch for System Platform allows me to effectively manage support and development demands, as well as contribute to the continuous improvement. This company that I current work for (Serrano) works with systems automation, systems integration and MES/MOM system integration and implementation projects. The company I work for is called 'Serrano Automação' and its areas of expertise are Industrial Engineering, 
Automation and Integration, Systems Maintenance and Information Systems. We are partners with several companies such as 
Siemens, Rockewell, AVEVA, Scheiner electric. 
I would describe myself (Bruno) as an industrious individual who takes pride in working hard to achieve my goals. I am results-driven and constantly strive to deliver high-quality work that meets or
exceeds expectations. I am ambitious and always looking for opportunities to learn and grow both personally and professionally. I
am also a collaborative team player who enjoys working with others to achieve common goals and solve complex problems. Overall, I 
believe my work ethic, drive, and team-oriented mindset make me an asset in any organization. 
${GetSkills()}
Some of my personal projects worth mentioning:   
${GetProjects()}  
AWARDS AND ACHIEVEMENTS: 
1) 1st Place in Engineering and Insper Institute highlight Award at Brazilian Science and Engineering Fair FEBRACE, São Paulo, 2019 edition. 
2) 2nd Place in Electronic Engineering, 33rd International Science and Technology Exhibition, MOSTRATEC, 2018. 
3) Vote of Congratulation by Novo Hamburgo City Council, for representing the city at the Febrace 2019 fair award in 1st place in Engineering.      
Professional Qualifications: 
${GetCertifications()} 
Languages: advaced english. intermeditate spanish. german begginer `;

export const GPT_Text_Source = "Based on the following Text: '" + GPT_Text_Fonte + "' Answer based on this text about Bruno what can you say about the topic: ";

export const GPT_Text_Final = `.IF YOU DONT have the Answer, add this sentence at the end:'I still don't have this information' 
BUT if you DO HAVE an Answer, add the following sentence at the end:'I recommend that for more information, read the curriculum and take a look at Bruno's porfifolio, because I might be missing some information`;
