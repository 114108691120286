import React, { useEffect, useRef } from "react";
import "./Academic.css";
import { connect } from "react-redux";
import { ImCalendar } from "react-icons/im";
import { MdLocationPin } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import { Evento } from "../firebase/metodos";
import { AcademicData, AcademicSectionData } from "../../data/Academic";
import { EventsType } from "../../data/Events";

const Academic = (props) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (props.User.Section === "Academic") {
      if (window.innerWidth < 1000) {
        const id = "Academic";
        const yOffset = -400;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [props.User.Section]);

  return (
    <div id="Academic" ref={myRef} className="Academic">
      <div className="AcademicTitle">{AcademicSectionData[props.User.Language].Title}</div>
      <div className="Divider">
        {" "}
        <div></div>{" "}
      </div>

      {[...AcademicData[props.User.Language]].map((Degree) => {
        return (
          <div className="AcademicItem">
            <div className="AcademicItemPhoto">
              <img src={Degree.SchoolPhotoURL} alt="" />
              <div onClick={(e) => Evento(EventsType.Academic, Degree.SchoolName)} className="AcademicItemCompanyName">
                <a target="_blank" href={Degree.URL}>
                  {Degree.SchoolName}
                </a>
              </div>
            </div>
            <div className="AcademicItemDescContainer">
              <div className="AcademicItemRole">
                <FaGraduationCap /> {Degree.Degree}
              </div>

              <div className="AcademicItemPeriod">
                <ImCalendar /> {Degree.Period}
              </div>
              <div className="AcademicItemLocation">
                <MdLocationPin /> {Degree.Location}
              </div>
              <div className="AcademicItemDesc">{Degree.Desc}</div>
              <div className="AcademicTags">
                {Degree.Tags.map((TAG) => {
                  return (
                    <div key={uuidv4()} className="AcademicTag">
                      {TAG}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ConnectedAcademic = connect((state) => {
  return {
    User: state.User,
  };
})(Academic);

export default ConnectedAcademic;
