export const EventsType = {
  Projects: "Projects",
  SocialNav: "SocialNav",
  Navbar: "Navbar",
  Introduction: "Introduction",
  Experience: "Experience",
  Academic: "Academic", 
  Courses: "Courses",
  Certifications: "Certifications",
  Geral: "Geral",
  GPTSearch: "GPTSearch",
};
