import "./App.css";
import Header from "./components/header/Header";
import Introduction from "./components/introduction/Introduction";
import "bootstrap/dist/css/bootstrap.min.css";
import About from "./components/about/About";
import Projects from "./components/projects/Projects";
import Experience from "./components/experience/Experience";
import Academic from "./components/academic/Academic";
import Certifications from "./components/certifications/Certifications";
import Courses from "./components/courses/Courses";
import ScrollTop from "./components/scrollTop/ScrollTop";
import Contact from "./components/contact/Contact";
import Divider from "./components/divider/Divider";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import { useEffect } from "react";

import { getRawFingerprint } from "react-fingerprint";
import { hashString } from "react-hash-string";
import { getUso } from "./components/firebase/metodos";
import { setUso, setUsoSaved } from "./store/actions/UserActions";
import store from "./store/store";

const DefaultUso = {
  Vezes: 1,
  Fingerprint: "",
  docID: "",
  AboutTab: 0,
  ProjectsTab: 0,
  ExperienceTab: 0,
  Academic: 0,
  ContactTab: 0,
  Resume: 0,
  LinkedinNav: 0,
  GithubNav: 0,
  FacebookNav: 0,
  InstagramNav: 0,
  LinkedinContact: 0,
  GithubContact: 0,
  FacebookContact: 0,
  InstagramContact: 0,
  BioAjust: 0,
  ChatGPTSearch: 0,
  ChatGPTSearchSelected: 0,
  ServiceIt: 0,
  Flashcards: 0,
  NotesApp: 0,
  Oneguitar: 0,
  Asis: 0,
  NotesAppLite: 0,
  SerranoLink: 0,
  Unisinos: 0,
  Uninter: 0,
  Liberato: 0,
  ToUp: 0,
  ToDown: 0,
  innerWidth: 0,
  outerWidth: 0,
  Language: "Eng",
  Location: "",
  CertificationItem: 0,
  CourseItem: 0,
  LanguageChange: 0,
  Navegador: "",
  NavegadorLingua: "",
  Data: "",
};

function App() {
  useEffect(() => {
    getRawFingerprint()
      .then((r) => {
        //const Finger = r.webgl.renderer + window.outerWidth + r.browser.app.codename + r.browser.userAgent
        const Finger = JSON.stringify(r);
        getUso(hashString(Finger)).then((UsoFirebase) => {
          //console.log(UsoFirebase)
          if (Object.keys(UsoFirebase).length !== 0) {
            var UsoObj = { ...UsoFirebase };
            UsoObj.Vezes = UsoObj.Vezes + 1;
            store.dispatch(setUso({ ...DefaultUso, ...UsoObj }));
            store.dispatch(setUsoSaved(true));
          }
        });
      })
      .catch((erro) => {
        //console.log(erro)
      });
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <Introduction />
              <Divider />
              <About />
              <Divider />
              <Projects />
              <Divider />
              <Experience />
              <Divider />
              <Academic />
              <Divider />
              <Certifications />
              <Divider />
              <Courses />
              <Divider />
              <Contact />
              <ScrollTop></ScrollTop>
            </>
          }
        />

        <Route path="/Teste" element={<Dashboard />} />

        <Route
          path="*"
          element={
            <>
              <Header />
              <Introduction />
              <Divider />
              <About />
              <Divider />
              <Projects />
              <Divider />
              <Experience />
              <Divider />
              <Academic />
              <Divider />
              <Certifications />
              <Divider />
              <Courses />
              <Divider />
              <Contact />
              <ScrollTop></ScrollTop>
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
