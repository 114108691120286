import React, { useEffect, useRef } from "react";
import "./Projects.css";
import { connect } from "react-redux";
import { Evento } from "../firebase/metodos";
import { ProjectsData, ProjectsSectionData } from "../../data/Projects";
import { EventsType } from "../../data/Events";

const Projects = (props) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (props.User.Section === "Projects") {
      if (window.innerWidth < 1000) {
        const id = "Projects";
        const yOffset = -400;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [props.User.Section]);

  return (
    <div id="Projects" ref={myRef} className="Projects">
      <div className="ProjectsTitle">{ProjectsSectionData[props.User.Language].Title}</div>

      <div className="Divider">
        <div></div>
      </div>
      <div className="ProjectsDesc">{ProjectsSectionData[props.User.Language].ProjectsDesc}</div>

      {[...ProjectsData[props.User.Language]].map((Project) => {
        return (
          <div className="Project">
            <div className="ProjectImage">
              <img src={Project.ThumbURL} alt="Project Thumbnail" className={Project.ID === "One Guitar" || Project.ID === "ASIS Web" ? "PersonalProject" : ""} />
            </div>
            <div className="ProjectInfo">
              <div
                onClick={(e) => {
                  Evento(EventsType.Projects, Project.ID);
                }}
                className="ProjectName"
              >
                <a target="_blank" href={Project.ProjectURL}>
                  {" "}
                  {Project.Name}
                </a>
              </div>
              <div className="ProjectDesc">{Project.Desc}</div>
              <div className="ProjectTags">
                {Project.Tags.map((Tag) => {
                  return <div className="ProjectTag">{Tag}</div>;
                })}
              </div>
              <div className="ProjectLink">
                <button className="DefaultButton">
                  <a
                    onClick={(e) => {
                      Evento(EventsType.Projects, Project.ID);
                    }}
                    target="_blank"
                    href={Project.ProjectURL}
                  >
                    {ProjectsSectionData[props.User.Language].AcessButton}{" "}
                  </a>{" "}
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ConnectedProjects = connect((state) => {
  return {
    User: state.User,
  };
})(Projects);

export default ConnectedProjects;
