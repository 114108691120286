import React, { useEffect, useRef, useState } from "react";
import "./Contact.css";
import Facebook from "../../images/Facebook.png";
import Instagram from "../../images/Instagram.jpg";
import Github from "../../images/Github.png";
import LinkedIn from "../../images/Linkedin.png";
import { connect } from "react-redux";
import emailjs from "@emailjs/browser";
import { TailSpin } from "react-loader-spinner";
import { BsCheckCircleFill } from "react-icons/bs";
import { Evento } from "../firebase/metodos";
import { SocialLinks } from "../../data/Header";
import { ContactWords } from "../../data/Contact";
import { EventsType } from "../../data/Events";

const Contact = (props) => {
  const [Email, setEmail] = useState("");
  const [Nome, setNome] = useState("");
  const [Mensagem, setMensagem] = useState("");
  const [Sending, setSending] = useState(false);
  const [Filled, setFilled] = useState(false);
  const [Sent, setSent] = useState(false);

  const myRef = useRef(null);

  useEffect(() => {
    if (props.User.Section === "Contact") {
      if (window.innerWidth < 1000) {
        const id = "Contact";
        const yOffset = -400;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [props.User.Section]);

  useEffect(() => {
    if (Email && Nome && Mensagem) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [Email, Nome, Mensagem]);

  const sendEmail = () => {
    const templateParams = {
      from_name: Nome,
      message: Mensagem,
      email: Email,
    };

    if (Email && Nome && Mensagem) {
      setSending(true);
      emailjs
        .send(process.env.REACT_APP_EMAILJS_SERVICE_CONTACT, process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT, templateParams, process.env.REACT_APP_EMAILJS_KEY_CONTACT)
        .then((response) => {
          //console.log("SERACH OK", response);
          setEmail("");
          setNome("");
          setMensagem("");
          setSending(false);
          setSent(true);
          setTimeout(() => {
            setSent(false);
          }, 1500);
        })
        .catch((erro) => {
          setSending(false);
          //console.log("SERACH ERROR", erro);
        });
    }
  };

  return (
    <div id="Contact" ref={myRef} className="ContactContainer">
      <div className="ContactTitle">{ContactWords[props.User.Language].Title}</div>
      <div className="Divider">
        {" "}
        <div></div>{" "}
      </div>

      <div className="Contact">
        {!Sending && !Sent && (
          <div className="ContactForm">
            <div className="ContactInputGroup">
              <label>Email</label>
              <input type="text" placeholder={ContactWords[props.User.Language].EMailPlaceholder} value={Email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="ContactInputGroup">
              <label>Name</label>
              <input type="text" placeholder={ContactWords[props.User.Language].NomePlaceholder} value={Nome} onChange={(e) => setNome(e.target.value)} />
            </div>
            <div className="ContactInputGroup">
              <label>Message</label>
              <textarea placeholder={ContactWords[props.User.Language].MensagemPlaceholder} value={Mensagem} onChange={(e) => setMensagem(e.target.value)}></textarea>
            </div>
            <div className="">
              <button disabled={!Filled} className={Filled ? "SendEmailButton ButtonFilled" : "SendEmailButton ButtonNotFilled"} onClick={sendEmail}>
                {ContactWords[props.User.Language].ButtonSend}
              </button>
            </div>
          </div>
        )}

        {Sending && !Sent && (
          <div>
            {" "}
            <TailSpin color="var(--Secondary)" />{" "}
          </div>
        )}

        {Sent && (
          <div className="Sent">
            <div>
              <BsCheckCircleFill />
              {ContactWords[props.User.Language].SentMessage}
            </div>
          </div>
        )}

        <div className="SocialMedia">
          <div className="SocialMediaItem">
            <img src={LinkedIn} alt="" />

            <a onClick={(e) => Evento(EventsType.Contact, "Linkedin")} target="_blank" href={SocialLinks.Linkedin}>
              LinkedIn
            </a>
          </div>
          <div className="SocialMediaItem">
            <img src={Instagram} alt="" />
            <a onClick={(e) => Evento(EventsType.Contact, "Instagram")} target="_blank" href={SocialLinks.Instagram}>
              Instagram
            </a>
          </div>
          <div className="SocialMediaItem">
            <img src={Facebook} alt="" />
            <a onClick={(e) => Evento(EventsType.Contact, "Facebook")} target="_blank" href={SocialLinks.Facebook}>
              Facebook
            </a>
          </div>
          <div className="SocialMediaItem">
            <img src={Github} alt="" />
            <a onClick={(e) => Evento(EventsType.Contact, "Github")} target="_blank" href={SocialLinks.Github}>
              GitHub
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConnectedContact = connect((state) => {
  return {
    User: state.User,
  };
})(Contact);

export default ConnectedContact;
