// ############### TAG CLOUD ############### //

export const TagCloudWords = ["HTML", "CSS", "ReactJS", "JavaScript", "NodeJs", ".NET", "SQL Sever", "C#", "PHP", "Jquery", "TailwindCss", "Typescript", "ES6", "GIT", "Intouch", "QuickScript", "C++", "Java", "Python", "Front-End", "Back-End", "AngularJS", "System Platform", "Database Modeling", "C", "Express", "HMIs", "SCADA", "Troubleshooting", "RESTful API design", "UI", "UX", "JSON", "MySQL"];

export const MobileTagCloudWords = [
  ...TagCloudWords.map((Word) => {
    return { value: Word, count: 38 };
  }),
];

export const GetSkills = () => {
  let formattedString = "Skills: ";
  TagCloudWords.forEach((Academic, index) => {
    formattedString += `${Academic},`;
  });

  return formattedString;
};
