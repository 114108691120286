import React, { useState, useEffect } from "react";
import "./Header.css";
import { SlSocialLinkedin, SlSocialInstagram, SlSocialGithub, SlSocialFacebook, SlMenu } from "react-icons/sl";
import { TfiClose } from "react-icons/tfi";
import LanguageSelector from "../languageSelector/LanguageSelector";
import Navbar from "react-bootstrap/Navbar";
import { connect } from "react-redux";
import store from "../../store/store";
import { setSection } from "../../store/actions/UserActions";
import emailjs from "@emailjs/browser";
import { Evento } from "../firebase/metodos";
import { HeaderOptions, SocialLinks } from "../../data/Header";
import { EventsType } from "../../data/Events";

const Header = (props) => {
  const SetSection = (Section) => {
    store.dispatch(setSection(Section));
  };

  const handleScroll = () => {};

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [Collapsed, setCollapsed] = useState(true);

  const setToggle = () => {
    if (window.innerWidth < 760) setCollapsed(!Collapsed);
  };

  const getTranslation = (Language, Text) => {
    return HeaderOptions[Language][Text];
  };

  const sendResumeEmail = () => {
    const templateParams = {
      lingua: props.User.Language,
      local: Intl.DateTimeFormat().resolvedOptions().timeZone,
      tamanhoAtual: window.innerWidth,
      tamanhoNormal: window.outerWidth,
    };

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, templateParams, process.env.REACT_APP_EMAILJS_KEY);
  };

  const getResumeLink = () => {
    if (props.User.Language == "Eng") return SocialLinks.CurriculoEn;
    else if (props.User.Language == "PTBR") return SocialLinks.CurriculoPTBR;
    else return SocialLinks.CurriculoEn;
  };

  return (
    <Navbar expanded={!Collapsed} expand="md" onToggle={setToggle} className={!Collapsed ? "NavBarHeader NavOpened" : "NavBarHeader"}>
      <Navbar.Toggle aria-controls="basic-navbar-nav">
        <div className="NavBarHamburguer">
          {Collapsed && <SlMenu />}
          {!Collapsed && <TfiClose />}
        </div>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <div className="Header">
          <div className="HeaderIcons">
            <a target="_blank" href={SocialLinks.Linkedin}>
              <SlSocialLinkedin
                onClick={(e) => {
                  setToggle();
                  Evento(EventsType.SocialNav, "Linkedin");
                }}
              />
            </a>
            <a target="_blank" href={SocialLinks.Github}>
              <SlSocialGithub
                onClick={(e) => {
                  setToggle();
                  Evento(EventsType.SocialNav, "Github");
                }}
              />
            </a>
            <a target="_blank" href={SocialLinks.Facebook}>
              <SlSocialFacebook
                onClick={(e) => {
                  setToggle();
                  Evento(EventsType.SocialNav, "Facebook");
                }}
              />
            </a>
            <a target="_blank" href={SocialLinks.Instagram}>
              <SlSocialInstagram
                onClick={(e) => {
                  setToggle();
                  Evento(EventsType.SocialNav, "Instagram");
                }}
              />
            </a>
          </div>

          <div className="HeaderLinks">
            <div
              className="HeaderLink"
              onClick={(e) => {
                SetSection("About");
                setToggle();
                Evento(EventsType.Navbar, "About");
              }}
            >
              <span>1.</span>
              <span>{HeaderOptions[props.User.Language].About}</span>
            </div>
            <div
              className="HeaderLink"
              onClick={(e) => {
                SetSection("Blog");
                setToggle();
                Evento(EventsType.Navbar, "Blog");
              }}
            >
              <span>2.</span>
              <span>
                <a className="BlogLink" target="_blank" href="https://bkappi.com">
                  Blog
                </a>
              </span>
            </div>
            <div
              className="HeaderLink"
              onClick={(e) => {
                SetSection("Projects");
                setToggle();
                Evento(EventsType.Navbar, "Projects");
              }}
            >
              <span>3.</span>
              <span>{HeaderOptions[props.User.Language].Projects}</span>
            </div>
            <div
              className="HeaderLink"
              onClick={(e) => {
                SetSection("Experience");
                setToggle();
                Evento(EventsType.Navbar, "Experience");
              }}
            >
              <span>4.</span>
              <span>{HeaderOptions[props.User.Language].Experience}</span>
            </div>
            <div
              className="HeaderLink"
              onClick={(e) => {
                SetSection("Academic");
                setToggle();
                Evento(EventsType.Navbar, "Academic");
              }}
            >
              <span>5.</span>
              <span>{HeaderOptions[props.User.Language].Academic}</span>
            </div>
            <div
              className="HeaderLink"
              onClick={(e) => {
                SetSection("Contact");
                setToggle();
                Evento(EventsType.Navbar, "Contact");
              }}
            >
              <span>6.</span>
              <span>{HeaderOptions[props.User.Language].Contact}</span>
            </div>
            <a target="_blank" onClick={sendResumeEmail} href={getResumeLink()}>
              <button
                className="DefaultButton ResumeButton"
                onClick={(e) => {
                  setToggle();
                  Evento(EventsType.Navbar, "Resume");
                }}
              >
                {HeaderOptions[props.User.Language].Resume}
              </button>
            </a>
          </div>

          <div className="LanguageLearn">
            <LanguageSelector />
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

const ConnectedHeader = connect((state) => {
  return {
    User: state.User,
  };
})(Header);

export default ConnectedHeader;
