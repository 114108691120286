// ############### CERTIFICATIONS ############### //

export const CertificationSectionData = {
  En: {
    Title: "Certifications",
  },
  PTBR: {
    Title: "Certificações",
  },
};

export const CertificationsData = {
  En: ["Qualification as Systems Analyst and Developer", "Professional Qualification in Management Processes", "Professional Software Tester Qualification", "Professional Software Designer Qualification", "Full Programmer Professional Qualification", "Software Quality Manager Professional Qualification", "Professional Qualification of Software Project Manager", "Software Requirement Analyst Professional Qualification", "Wonderware Intouch for System Platform", "AVEVA System Platform 2014/2017/2020", "AVEVA Intouch 2020"],
  PTBR: ["Profissional Analista e Desenvolvedor de Sistemas", "Qualificação Profissional em Processos Gerenciais", "Qualificação Profissional De Testador De Software", "Qualificação Profissional De Projetista De Software", "Qualificação Profissional De Programador Pleno", "Qualificação Profissional De Gerente De Qualidade De Software", "Qualificação Profissional De Gerente De Projetos De Software", "Qualificação Profissional De Analista De Requisito De Software", "Wonderware Intouch for System Platform", "AVEVA System Platform 2014/2017/2020", "AVEVA Intouch 2020"],
};

export const GetCertifications = () => {
  let formattedString = "Certifications: ";
  CertificationsData.En.forEach((Item, index) => {
    formattedString += `Course ${Item}, `;
  });

  return formattedString;
};
