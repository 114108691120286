import React, { useEffect, useRef } from "react";
import "./Experience.css";
import { connect } from "react-redux";
import { ImCalendar } from "react-icons/im";
import { MdLocationPin, MdPerson } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { Evento } from "../firebase/metodos";
import { ExperienceData, ExperienceSectionData } from "../../data/Experience";
import { EventsType } from "../../data/Events";

const Experience = (props) => {
  const myRef = useRef(null);

  useEffect(() => {
    if (props.User.Section === "Experience") {
      if (window.innerWidth < 1000) {
        const id = "Experience";
        const yOffset = -400;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [props.User.Section]);

  return (
    <div id="Experience" ref={myRef} className="Experience">
      <div className="ExperienceTitle">{ExperienceSectionData[props.User.Language].Title}</div>
      <div className="Divider">
        {" "}
        <div></div>{" "}
      </div>

      {[...ExperienceData[props.User.Language]].map((Job) => {
        return (
          <div className="ExperienceItem">
            <div className="ExperienceItemPhoto">
              <img src={Job.CompanyPhotoURL} alt="" />
              <div onClick={(e) => Evento(EventsType.Experience, Job.ID)} className="ExperienceItemCompanyName">
                <a target="_blank" href={Job.URL}>
                  {Job.CompanyName}
                </a>
              </div>
            </div>
            <div className="ExperienceItemDescContainer">
              <div className="ExperienceItemRole">
                <MdPerson />
                {Job.RoleName}
              </div>

              <div className="ExperienceItemPeriod">
                <ImCalendar />
                {Job.Period}
              </div>
              <div className="ExperienceItemLocation">
                <MdLocationPin />
                {Job.Location}
              </div>
              <div className="ExperienceItemDesc"> {Job.Desc}</div>

              <div className="ExperienceTags">
                {Job.Tags.map((TAG) => {
                  return (
                    <div key={uuidv4()} className="ExperienceTag">
                      {TAG}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ConnectedExperience = connect((state) => {
  return {
    User: state.User,
  };
})(Experience);

export default ConnectedExperience;
