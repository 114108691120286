import React, { useState } from "react";
import "./LanguageSelector.css";
import { connect } from "react-redux";
import En from "../../images/Eng.png";
import PTBR from "../../images/PTBR.png";
import { setLanguage as SetCurrentLanguage } from "../../store/actions/UserActions";
import store from "../../store/store";
import { v4 as uuidv4 } from "uuid";
import { Evento } from "../firebase/metodos";
import { EventsType } from "../../data/Events";

const LanguageSelector = (props) => {
  const [Language, setLanguage] = useState("En");
  const [ShowOptions, setShowOptions] = useState(false);

  const Options = ["En", "PTBR"];

  const getLanguageIcon = (Choice) => {
    if (Choice === "En") return En;
    else if (Choice === "PTBR") return PTBR;
  };

  const SetLanguage = (Choice) => {
    setLanguage(Choice);
    setShowOptions(!ShowOptions);
    store.dispatch(SetCurrentLanguage(Choice));
    Evento(EventsType.Navbar, "LanguageChange");
  };

  const Switch = () => {
    setShowOptions(!ShowOptions);
  };

  return (
    <div className="LanguageSelector">
      <img className="SelectedLanguage" src={getLanguageIcon(Language)} alt="" onClick={Switch} />
      {ShowOptions && (
        <div className="Options">
          {Options.filter((OP) => OP !== Language).map((option) => {
            return <img className="LanguageOption" key={uuidv4()} src={getLanguageIcon(option)} onClick={(e) => SetLanguage(option)} />;
          })}
        </div>
      )}
    </div>
  );
};

const ConnectedLanguageSelector = connect((state) => {
  return {
    User: state.User,
  };
})(LanguageSelector);

export default ConnectedLanguageSelector;
