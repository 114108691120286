// ############### COURSES ############### //

export const CoursesSectionData = {
  En: {
    Title: "Courses and Trainings",
  },
  PTBR: {
    Title: "Cursos e Treinamentos",
  },
};

export const CoursesData = {
  En: [
    {
      SchoolName: "Udemy",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Udemy.png",
      CourseName: "The Complete React Developer Course (w/ Hooks and Redux)",
      CourseDate: "Out 2021",
      CourseDuration: "25 Hours",
    },
    {
      SchoolName: "Udemy",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Udemy.png",
      CourseName: "The Complete Node.js Developer Course (3rd Edition)",
      CourseDate: "Aug 2021",
      CourseDuration: "30 Hours",
    },
    {
      SchoolName: "FreeCodeCamp",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/FreeCode.png",
      CourseName: "Responsive Web Design",
      CourseDate: "Mar 2017",
      CourseDuration: "30 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "InTouch for System Platform 2017",
      CourseDate: "Mar 2019",
      CourseDuration: "40 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA OMI 2020",
      CourseDate: "Dez 2022",
      CourseDuration: "40 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA Intouch 2020",
      CourseDate: "Feb 2023",
      CourseDuration: "40 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA System Platform 2017",
      CourseDate: "Mar 2019",
      CourseDuration: "40 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "",
      CourseName: "AVEVA System Platform 2020",
      CourseDate: "Dez 2021",
      CourseDuration: "40 Hours",
    },
    {
      SchoolName: "SoloLearn",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Solo.png",
      CourseName: "Complete PHP Course",
      CourseDate: "Jun 2017",
      CourseDuration: "20 Hours",
    },
    {
      SchoolName: "SoloLearn",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Solo.png",
      CourseName: "Complete Python Course",
      CourseDate: "Jun 2017",
      CourseDuration: "20 Hours",
    },
    {
      SchoolName: "Bradesco",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Bradesco.png",
      CourseName: "Java Programmin Language",
      CourseDate: "Aug 2022",
      CourseDuration: "10 Hours",
    },
    {
      SchoolName: "Bradesco",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Bradesco.png",
      CourseName: "HTML e CSS in Practice",
      CourseDate: "Aug 2022",
      CourseDuration: "24 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA Operations",
      CourseDate: "Aug 2023",
      CourseDuration: "32 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA Quality",
      CourseDate: "Aug 2023",
      CourseDuration: "24 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA MES Performance",
      CourseDate: "Aug 2023",
      CourseDuration: "32 Hours",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA Model Driven",
      CourseDate: "Aug 2023",
      CourseDuration: "24 Hours",
    },
  ],
  PTBR: [
    {
      SchoolName: "Udemy",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Udemy.png",
      CourseName: "The Complete React Developer Course (w/ Hooks and Redux)",
      CourseDate: "Out 2021",
      CourseDuration: "25 Horas",
    },
    {
      SchoolName: "Udemy",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Udemy.png",
      CourseName: "The Complete Node.js Developer Course (3rd Edition)",
      CourseDate: "Aug 2021",
      CourseDuration: "30 Horas",
    },
    {
      SchoolName: "FreeCodeCamp",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/FreeCode.png",
      CourseName: "Web Design Responsivo - FreeCodeCamp",
      CourseDate: "Mar 2017",
      CourseDuration: "30 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "InTouch for System Platform 2017",
      CourseDate: "Mar 2019",
      CourseDuration: "40 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA OMI 2020",
      CourseDate: "Dez 2022",
      CourseDuration: "40 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA Intouch 2020",
      CourseDate: "Feb 2023",
      CourseDuration: "40 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA System Platform 2017",
      CourseDate: "Mar 2019",
      CourseDuration: "40 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA System Platform 2020",
      CourseDate: "Dez 2021",
      CourseDuration: "40 Horas",
    },
    {
      SchoolName: "SoloLearn",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Solo.png",
      CourseName: "Curso Completo PHP Course",
      CourseDate: "Jun 2017",
      CourseDuration: "20 Horas",
    },
    {
      SchoolName: "SoloLearn",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Solo.png",
      CourseName: "Curso Completo Python Course",
      CourseDate: "Jun 2017",
      CourseDuration: "20 Horas",
    },
    {
      SchoolName: "Bradesco",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Bradesco.png",
      CourseName: "Programação em Linguagem Java",
      CourseDate: "Ago 2022",
      CourseDuration: "10 Horas",
    },
    {
      SchoolName: "Bradesco",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Bradesco.png",
      CourseName: "HTML e CSS na Prática",
      CourseDate: "Ago 2022",
      CourseDuration: "24 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA MES Operations",
      CourseDate: "Ago 2023",
      CourseDuration: "32 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA MES Quality",
      CourseDate: "Ago 2023",
      CourseDuration: "24 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA MES Performance",
      CourseDate: "Ago 2023",
      CourseDuration: "32 Horas",
    },
    {
      SchoolName: "AVEVA",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Aveva.png",
      CourseName: "AVEVA Model Driven",
      CourseDate: "Ago 2023",
      CourseDuration: "24 Horas",
    },
  ],
};

export function GetCourses() {
  let formattedString = "Courses: ";
  CoursesData.En.forEach((Academic, index) => {
    formattedString += `Course ${index + 1}:`;
    formattedString += `Institution: ${Academic.SchoolName}`;
    formattedString += `Course: ${Academic.CourseName}`;
    formattedString += `Date: ${Academic.CourseDate} `;
  });

  return formattedString;
}
