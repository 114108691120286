// ############### ACADEMIN ############### //

export const AcademicSectionData = {
  En: {
    Title: "Academic education",
  },
  PTBR: {
    Title: "Formação Acadêmica",
  },
};

export const AcademicData = {
  En: [
    {
      ID: "Unisinos",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Unisinos.png",
      SchoolName: "Unisinos",
      Degree: "Systems Analysis and Development",
      Period: "2020 - 2023",
      Location: "São Leopoldo, RS, Brazil",
      Desc: "My degree in Analysis and Development of Systems has provided me with a solid and comprehensive education in areas such as programming, database management, computer networking, software engineering, artificial intelligence, and information security. Throughout my studies, I have acquired skills in problem-solving, logical reasoning, object-oriented programming, and the development of web and mobile systems, as well as knowledge of programming languages and development technologies. This has prepared me to design, develop, and implement information systems for companies and organizations, using the best practices and technologies available in the market. My education in Analysis and Development of Systems enables me to stay up-to-date and strive for excellence in everything I do.",
      URL: "https://bit.ly/UnisinosBruno",
      Tags: ["Javascript", "NodeJs", "ReactJs", "Web Design", "Web Development", "HTML", "CSS", "System analysis", "Systems design", "Software development", "Project management", "User experience", "Data modeling", "Software engineering", "Testing and debugging", "Network architecture"],
    },
    {
      ID: "Uninter",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Uninter.png",
      SchoolName: "Uninter",
      Degree: "Management Processes",
      Period: "2020 - 2023",
      Location: "Novo Hamburgo, Brazil",
      Desc: "My graduation in Management Processes allowed me to lead teams, manage projects and processes efficiently, in addition to having a strategic vision of the business. During the course, I acquired knowledge in areas such as finance, accounting, marketing, logistics and people management, which provided me with a complete and solid education. With this, I am prepared to contribute to the continuous improvement of production processes and work in several areas, such as entrepreneurship, project management, marketing, sales, logistics, among others. My background in Management Processes allows me to always be up to date and seek excellence in everything I do.",
      URL: "https://bit.ly/UninterBruno",
      Tags: ["Process Improvement", "Strategic Planning", "Project Management", "Leadership", "Operations management", "Change management", "Risk management", "Decision-making", "Negotiation", "Performance management", "Innovation management", "Entrepreneurship", "Business ethics"],
    },
    {
      ID: "Liberato",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Liberato.png",
      SchoolName: "Fundação Liberato",
      Degree: "Electronics Technician",
      Period: "2015 - 2019",
      Location: "Novo Hamburgo, Brazil",
      Desc: "I finished high school integrated with a technical course in electronics at FUNDAÇÃO LIBERATO SALZANO VIEIRA DA CUNHA, which gave me technical skills in electronics and programming. I learned to design electronic circuits, program microcontrollers and identify electronic components. With a strong focus on programming and software design in the later years of the course, he developed practical programming skills and acquired knowledge in areas such as programming logic and algorithms. I acquired skills in problem solving, critical thinking and teamwork, becoming a qualified professional able to add value to companies that needed skills in electronics and programming.",
      URL: "https://bit.ly/LiberatoBruno",
      Tags: ["Electronics engineering", "Computer programming", "Algorithm design", "Circuit design", "Microcontrollers", "Embedded systems", "Digital signal processing", "Robotics", "Analog and digital electronics", "Computer networking  ", "Circuit testing  ", "Internet of Things (IoT)", "HTML", "C", "Javascript", "PHP", "CSS", "MySQL"],
    },
  ],
  PTBR: [
    {
      ID: "Unisinos",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Unisinos.png",
      SchoolName: "Unisinos",
      Degree: "Análise e Desenvolvimento de Sistemas",
      Period: "2020 - 2023",
      Location: "São Leopoldo, RS, Brasil",
      Desc: "A minha graduação em Análise e Desenvolvimento de Sistemas me proporcionou uma formação sólida e completa em áreas como programação, banco de dados, redes de computadores, engenharia de software, inteligência artificial e segurança da informação. Durante o curso, adquiri habilidades em solução de problemas, raciocínio lógico, programação orientada a objetos, desenvolvimento de sistemas web e móveis, além de conhecimentos em linguagens de programação e tecnologias de desenvolvimento. Com isso, estou preparado para projetar, desenvolver e implementar sistemas de informação para empresas e organizações, utilizando as melhores práticas e tecnologias disponíveis no mercado. A minha formação em Análise e Desenvolvimento de Sistemas me permite estar sempre atualizado e buscar a excelência em tudo o que faço",
      URL: "https://bit.ly/UnisinosBruno",
      Tags: ["Javascript", "NodeJs", "ReactJs", "Web Design", "Desenvolvimento Web", "HTML", "CSS", "Análise de Sistemas", "Design de Sistemas", "Desenvolvimento de Software", "Gerenciamento de Projetos", "Experiência do Usuário", "Modelagem de Dados", "Engenharia de Software", "Testes e Depuração", "Arquitetura de Rede"],
    },
    {
      ID: "Uninter",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Uninter.png",
      SchoolName: "Uninter",
      Degree: "Processos Gerenciais",
      Period: "2020 - 2023",
      Location: "Novo Hamburgo, Brasil",
      Desc: "Minha graduação em Processos Gerenciais me possibilitou liderar equipes, gerenciar projetos e processos com eficiência, além de ter uma visão estratégica do negócio. Durante o curso, adquiri conhecimentos em áreas como finanças, contabilidade, marketing, logística e gestão de pessoas, o que me proporcionou uma formação completa e sólida. Com isso, estou preparado para contribuir com a melhoria contínua dos processos produtivos e atuar em diversas áreas, como empreendedorismo, gestão de projetos, marketing, vendas, logística, entre outras. Minha formação em Processos de Gestão me permite estar sempre atualizado e buscar a excelência em tudo que faço.",
      URL: "https://bit.ly/UninterBruno",
      Tags: ["Engenharia Eletrônica", "Programação de Computadores", "Projeto de Algoritmos", "Projeto de Circuitos", "Microcontroladores", "Sistemas Embarcados", "Processamento Digital de Sinais", "Robótica", "Eletrônica Analógica e Digital", "Redes de Computadores", "Teste de Circuitos", "Internet das Coisas (IoT)", "HTML", "C", "Javascript", "PHP", "CSS", "MySQL"],
    },
    {
      ID: "Liberato",
      SchoolPhotoURL: "https://bkappi.com/Images/Portfolio/Icons/Liberato.png",
      SchoolName: "Fundação Liberato",
      Degree: "Técnico em Eletrônica",
      Period: "2015 - 2019",
      Location: "Novo Hamburgo, Brasil",
      Desc: "Eu concluí o ensino médio integrado a um curso técnico em eletrônica na FUNDAÇÃO LIBERATO SALZANO VIEIRA DA CUNHA, o que me proporcionou habilidades técnicas em eletrônica e programação. Aprendi a projetar circuitos eletrônicos, programar microcontroladores e identificar componentes eletrônicos. Com um forte foco em programação e design de software nos últimos anos do curso, desenvolvi habilidades práticas de programação e adquiri conhecimentos em áreas como lógica de programação e algoritmos. Adquiri habilidades em resolução de problemas, pensamento crítico e trabalho em equipe, tornando-me um profissional qualificado capaz de agregar valor a empresas que necessitam de habilidades em eletrônica e programação.",
      URL: "https://bit.ly/LiberatoBruno",
      Tags: ["Engenharia Eletrônica", "Programação de Computadores", "Design de Algoritmos", "Projeto de Circuitos", "Microcontroladores", "Sistemas Embarcados", "Processamento de Sinais Digitais", "Robótica", "Eletrônica Analógica e Digital", "Redes de Computadores", "Teste de Circuitos", "Internet das Coisas (IoT)", "HTML", "C", "Javascript", "PHP", "CSS", "MySQL"],
    },
  ],
};

export const GetAcademic = () => {
  let formattedString = "Academic Degrees: ";
  AcademicData.En.forEach((Academic, index) => {
    formattedString += `Degree ${index + 1}:`;
    formattedString += `Institution: ${Academic.SchoolName}`;
    formattedString += `Degree: ${Academic.Degree}`;
    formattedString += `Period: ${Academic.Period}`;
    formattedString += `Location: ${Academic.Location}`;
    formattedString += `Description: ${Academic.Desc} `;
  });

  return formattedString;
};
