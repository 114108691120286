import React from 'react';
import './Divider.css'





const Divider = (props) => {




    return ( 
        <div className='DividerLine'>
            <div></div>

        </div>
    );
}


export default Divider

