import React, { useState, useEffect } from "react";
import { RiQuestionAnswerFill } from "react-icons/ri";
import "./ChatGPT.css";
import { TailSpin } from "react-loader-spinner";
import { connect } from "react-redux";
import { Evento } from "../firebase/metodos";
import { GPT_Text_Final, GPT_Text_Source, GPTPhrases, GPTSection } from "../../data/GPT";
import { EventsType } from "../../data/Events";
import emailjs from "@emailjs/browser";

const ChatGPT = (props) => {
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [Response, setResponse] = useState("");
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState("");

  useEffect(() => {
    const CurrentPhrases = GPTPhrases[props.User.Language];
    const phrase = CurrentPhrases[phraseIndex];
    const intervalId = setInterval(() => {
      setCurrentPhrase((prev) => {
        if (prev === phrase) {
          setPhraseIndex((prevIndex) => (prevIndex + 1) % CurrentPhrases.length);
          return "";
        } else {
          return phrase.slice(0, prev.length + 1);
        }
      });
    }, 200);
    return () => clearInterval(intervalId);
  }, [phraseIndex]);

  const SendQuestion = (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (prompt) {
      var text = "Carregando...";
      setIsLoading(true);
      setResponse(text);
      Evento(EventsType.Introduction, "ChatGPTSearch");

      const templateParams = {
        pesquisa: prompt,
        lingua: props.User.Language,
        local: Intl.DateTimeFormat().resolvedOptions().timeZone,
        resposta: text,
        tamanhoAtual: window.innerWidth,
        tamanhoNormal: window.outerWidth,
      };

      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_GPT, process.env.REACT_APP_EMAILJS_TEMPLATE_GPT, templateParams, process.env.REACT_APP_EMAILJS_KEY_GPT);

      const PROMPT = GPT_Text_Source + prompt + GPT_Text_Final + GPTSection[props.User.Language].AnswerLanguage;

      fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo", // Modelo de Linguagem
          //prompt: PROMPT,
          messages: [{ role: "user", content: PROMPT }],
          max_tokens: 2048, // tamanho da ChatGPTAnswer
          temperature: 0.3, // criatividade na ChatGPTAnswer
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          var text = json.choices[0]?.message?.content || "No Answer at the moment";
          setIsLoading(false);
          setResponse(text);
          Evento(EventsType.Introduction, "ChatGPTSearch");
          Evento(EventsType.GPTSearch, { Reponse: text, Promt: prompt });

          const templateParams = {
            pesquisa: prompt,
            lingua: props.User.Language,
            local: Intl.DateTimeFormat().resolvedOptions().timeZone,
            resposta: text,
            tamanhoAtual: window.innerWidth,
            tamanhoNormal: window.outerWidth,
          };

          emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_GPT, process.env.REACT_APP_EMAILJS_TEMPLATE_GPT, templateParams, process.env.REACT_APP_EMAILJS_KEY_GPT);
        })
        .catch((error) => {
          setIsLoading(false);
          setResponse("Service temporarily unavailable");
          //console.error("Error:", error);
        });
    }
  };

  return (
    <div className="ChatGPT">
      <p className="ChatGPTTile">
        {" "}
        <RiQuestionAnswerFill /> {GPTSection[props.User.Language].Ask}
      </p>
      <form className="ChatGPTSearchForm" onSubmit={SendQuestion}>
        <input id="prompt" type="text" value={prompt} onChange={(e) => setPrompt(e.target.value)} className="ChatGPTSearch" placeholder={currentPhrase} autoComplete="off" maxLength={100} onClick={(e) => Evento(EventsType.Introduction, "ChatGPTSearchSelected")} />
        <button className="DefaultButton ChatGPTSearchButton" type="submit">
          {GPTSection[props.User.Language].SearchButton}
        </button>
      </form>
      {isLoading ? (
        <div className="ChatGPTAnswer ChatGPTAnswerCentered">
          {" "}
          <TailSpin color="var(--Secondary)" />{" "}
        </div>
      ) : (
        <div className="ChatGPTAnswer">{Response}</div>
      )}

      {!isLoading && !Response && false && <div className="ChatGPTAnswer ChatGPTAnswerIntroduction ">Teste a inteligência artificial para buscar alguma informação que deseja</div>}
    </div>
  );
};

const ConnectedChatGPT = connect((state) => {
  return {
    User: state.User,
  };
})(ChatGPT);

export default ConnectedChatGPT;
