import "./Courses.css";
import { connect } from "react-redux";
import { ImCalendar } from "react-icons/im";
import { HiClock } from "react-icons/hi";
import { Evento } from "../firebase/metodos";
import { CoursesData, CoursesSectionData } from "../../data/Courses";
import { EventsType } from "../../data/Events";

const Courses = (props) => {
  return (
    <div className="CoursesContainer">
      <div className="CoursesTitle">{CoursesSectionData[props.User.Language].Title}</div>
      <div className="Divider">
        {" "}
        <div></div>{" "}
      </div>

      <div className="CoursesListContainer">
        {CoursesData[props.User.Language].map((Course) => {
          return (
            <div onClick={(e) => Evento(EventsType.Courses, Course.CourseName)} className="Course">
              <div className="Institution">
                <img src={Course.SchoolPhotoURL} alt="" />
                <div className="InstitutionName">{Course.SchoolName}</div>
              </div>
              <div className="CourseInfo">
                <div className="CourseName">{Course.CourseName}</div>
                <div className="CourseDatails">
                  <div className="CourseDate">
                    <ImCalendar /> {Course.CourseDate}
                  </div>
                  <div className="CourseHours">
                    <HiClock /> {Course.CourseDuration}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ConnectedCourses = connect((state) => {
  return {
    User: state.User,
  };
})(Courses);

export default ConnectedCourses;
