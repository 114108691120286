import React, { useState, useEffect } from "react";
import "./Introduction.css";
import { connect } from "react-redux";
import ChatGPT from "../chatGPT/ChatGPT";
import { v4 as uuidv4 } from "uuid";
import { Evento } from "../firebase/metodos";
import { IntroductionText } from "../../data/Introduction";
import { EventsType } from "../../data/Events";

const Introduction = (props) => {
  const getTranslation = (Language, Text) => {
    return IntroductionText[Language][Text];
  };

  const [IntroductionIndex, setIntroductionIndex] = useState(3);
  const [CurrentIntroduction, setCurrentIntroduction] = useState(getTranslation(props.User.Language, "Introduction3"));

  const [LengthOptions, setLengthOptions] = useState([
    { Id: 1, Selected: false },
    { Id: 2, Selected: false },
    { Id: 3, Selected: true },
    { Id: 4, Selected: false },
    { Id: 5, Selected: false },
    { Id: 6, Selected: false },
  ]);

  const ChangeSelected = (Index) => {
    var LengthOptionsCopy = [...LengthOptions];
    LengthOptionsCopy.forEach((OP) => (OP.Selected = false));
    LengthOptionsCopy[Index - 1].Selected = true;
    setLengthOptions([...LengthOptionsCopy]);
    setIntroductionIndex(Index);
    Evento(EventsType.Introduction, "BioAjust");
  };

  useEffect(() => {
    const text = "Introduction" + IntroductionIndex.toString();
    setCurrentIntroduction(getTranslation(props.User.Language, text));
  }, [IntroductionIndex, props.User.Language]);

  return (
    <div className="IntroductionContainer">
      <div className="Introduction">
        <span className="IntroductionGreeting">{getTranslation(props.User.Language, "Hi")}</span>
        <span className="IntroductionName">Bruno Kappi</span>

        <div className="IntroductionLengthContainer">
          <div className="IntroductionLengthOptions">
            <span>{getTranslation(props.User.Language, "IntroductionOptions")}</span>
            <div className="IntroductionLengthOptionsDots">
              {LengthOptions.map((Op) => {
                return <div key={uuidv4()} onClick={(e) => ChangeSelected(Op.Id)} className={Op.Selected ? "IntroductionLengthOption IntroductionLengthOptionActive" : "IntroductionLengthOption"}></div>;
              })}
            </div>
          </div>
        </div>

        <span className="IntroductionText">{CurrentIntroduction}</span>
      </div>
      <ChatGPT />
    </div>
  );
};

const ConnectedIntroduction = connect((state) => {
  return {
    User: state.User,
  };
})(Introduction);

export default ConnectedIntroduction;
