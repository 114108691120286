// ############### HEADER OPTIONS ############### //

export const HeaderOptions = {
  En: {
    About: "About",
    Blog: "Blog",
    Experience: "Experience",
    Work: "Work",
    Contact: "Contact",
    Resume: "Resume",
    Projects: "Projects",
    Academic: "Education/Trainings",
  },
  PTBR: {
    About: "Sobre",
    Blog: "Blog",
    Experience: "Experiência",
    Work: "Trabalho",
    Contact: "Contato",
    Resume: "Currículo",
    Projects: "Projetos",
    Academic: "Formação/Treinamentos",
  },
};

export const SocialLinks = {
  Instagram: "https://bit.ly/BrunoKappiInstagram",
  Linkedin: "https://bit.ly/BrunoKappiLinkedin",
  Facebook: "https://bit.ly/BrunoKappiFacebook",
  Github: "https://bit.ly/BrunoKappiGithub",
  CurriculoEn: "https://bit.ly/CurriculoENG",
  CurriculoPTBR: "https://bit.ly/CurriculoPTBR",
};
